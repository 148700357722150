import { View } from '@react-pdf/renderer';
import { GroupHeader } from './table-group-header';
import { Header } from './table-header';
import { TableNotice } from './table-notice';
import { Body } from './table-body';
import { styles } from './table-styles';

const CaloryTable = ({ values, headers, groupHeaders, date }: any) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.table}>
        <GroupHeader groupHeaders={groupHeaders} />
        <Header headers={headers} />
        <TableNotice />
        <Body values={values} date={date} />
      </View>
    </View>
  );
};

export { CaloryTable };
