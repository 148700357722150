import { PDF_SELECT_BACKGOUND_COLOR } from 'features/PDF/constants/style';

const SelectMenuColor = ({ isSelected }: any) => {
  return (
    <div
      style={{
        pointerEvents: 'none',
        position: 'absolute',
        width: '100%',
        height: '100%',
        ...(isSelected ? { backgroundColor: PDF_SELECT_BACKGOUND_COLOR } : {}),
      }}
    ></div>
  );
};

export default SelectMenuColor;
