import { useAppDispatch, useAppSelector } from 'app/hooks';
import { NutritionUnitMasterUpdatePayload } from 'models/masterdata';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  nutritionUnitMasterActions,
  selectNutritionUnitMasterData,
} from 'store/NutritionUnitMaster/nutritionUnitMasterSlice';
import NutritionUnitMasterForm from './components/NutritionUnitMasterForm';
import NutritionUnitMasterList from './components/NutritionUnitMasterList';
import useNutritionUnitMaster from './hooks/useNutritionUnitMaster';
import './style.scss';

export type NutritionUnitMasterListFormValues = {
  data: NutritionUnitMasterUpdatePayload[];
};

function NutritionUnitMasterFeature() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // hooks
  const { updateNutritionUnitMaster } = useNutritionUnitMaster();

  // refs
  const formRef = useRef<HTMLFormElement>(null);
  const nextDisplayOrder = useRef<number>(1);

  // states
  const [showForm, setShowForm] = useState(false);

  // selectors
  const nutritionUnitMasterList = useAppSelector(selectNutritionUnitMasterData);

  // side effects
  useEffect(() => {
    dispatch(nutritionUnitMasterActions.fetchNutritionUnitMasterList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nutritionUnitMasterList.length === 0) {
      nextDisplayOrder.current = 1;
      return;
    }

    const maxDisplayOrder = nutritionUnitMasterList.reduce(
      (max, item) => (item.display_order > max ? item.display_order : max),
      nutritionUnitMasterList[0].display_order
    );
    nextDisplayOrder.current = maxDisplayOrder + 1;
  }, [nutritionUnitMasterList]);

  //events
  function handleOpenForm() {
    setShowForm(true);
  }

  function handleCloseForm() {
    setShowForm(false);
  }

  function handleNutritionUnitMasterFormReflect() {
    dispatch(nutritionUnitMasterActions.fetchNutritionUnitMasterList());
  }

  function handleSubmit() {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  }

  function handleSubmitNutritionUnitMasterListForm(formValues: NutritionUnitMasterListFormValues) {
    (async () => {
      const { data, error } = await updateNutritionUnitMaster(formValues.data);
      if (data) {
        toast.success(data?.message);
        dispatch(nutritionUnitMasterActions.fetchNutritionUnitMasterList());
      } else {
        if (error && error.error) {
          toast.error(<div dangerouslySetInnerHTML={{ __html: error.message || t('App.unexpected_error') }}></div>);
        }
      }
    })();
  }

  return (
    <div className="page-content d-flex flex-column">
      {/* header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('NutritionUnitMaster.head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button onClick={handleSubmit}>{t('NutritionUnitMaster.update')}</Button>
              <Button onClick={handleOpenForm}>{t('NutritionUnitMaster.register')}</Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* list */}
      <NutritionUnitMasterList
        formRef={formRef}
        data={nutritionUnitMasterList}
        onSubmitForm={handleSubmitNutritionUnitMasterListForm}
      />

      {/* Form Modal */}
      {showForm &&
        createPortal(
          <NutritionUnitMasterForm
            nextDisplayOrder={nextDisplayOrder.current}
            onClose={handleCloseForm}
            onReflect={handleNutritionUnitMasterFormReflect}
          />,
          document.body
        )}
    </div>
  );
}

export default NutritionUnitMasterFeature;
