import { CHART_TEMLATES } from '../../Template';
import DatePickerCustom from 'features/CalorieManagement/PartsConfigurationSetting/components/DatePickerCustom';

const IntroductionFrame = ({ onDateChange, date, isAllowedEdit, template = 0 }: any) => {
  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: 6,
          paddingRight: 6,
          fontWeight: 'bold',
        }}
      >
        <div style={{ marginRight: '10px' }}>{`ランチ一覧 `}</div>
        <div>
          <div style={{ maxWidth: '110px' }}>
            <DatePickerCustom value={date} onChange={(date: Date) => onDateChange(date)} disabled={!isAllowedEdit} />
          </div>
        </div>
        <div style={{ marginLeft: '10px' }}> 改訂</div>
      </div>

      {CHART_TEMLATES[template].displayComponent}
    </div>
  );
};

export { IntroductionFrame };
