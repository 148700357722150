import { useCallback, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { ActionMeta } from 'react-select/dist/declarations/src';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

const Autocomplete = ({
  placeholder,
  loadOptions,
  onChange,
  debounceTime = 1000,
}: {
  placeholder?: string;
  loadOptions: (value: string, cb: (options: any[]) => void) => void;
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
  debounceTime?: number;
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [key, setKey] = useState<any>('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadOptions = useCallback(
    debounce((inputValue: string, callback: (options: any[]) => void) => {
      if (loadOptions) {
        loadOptions(inputValue, callback);
      }
    }, debounceTime),
    [loadOptions]
  );

  const handleChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    setInputValue('');
    setValue(newValue);
    // serves to re-render the component
    setKey(`${newValue}_${new Date().toISOString()}`);
    if (onChange) {
      onChange(newValue, actionMeta);
    }
    setInputValue(newValue.label);
  };

  const handleInputChange = (newInputValue: string, actionMeta: any) => {
    if (actionMeta.action === 'input-change') {
      setInputValue(newInputValue);
      debouncedLoadOptions(newInputValue, () => {});
    }
    if (newInputValue.length === 0) {
      setValue(null);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <AsyncSelect
        key={key}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            minWidth: '330px',
            minHeight: '29px',
            color: '#212529',
            borderColor: state.isFocused ? '#ffc080' : '#ced4da',
            boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(255, 128, 0, 0.25)' : 'none',
            '&:hover': {
              borderColor: state.isFocused ? '#ffc080' : '#ced4da',
            },
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
          }),
          valueContainer: (base) => ({
            ...base,
            padding: '0 0 0 5px',
          }),
          singleValue: (base) => ({
            ...base,
            padding: 0,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            color: '#212529',
            '&:hover': {
              backgroundColor: '#ffe6cc',
            },
          }),
        }}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        loadOptions={debouncedLoadOptions}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        noOptionsMessage={() => t('autoCompleteTranslation.no_options')}
        loadingMessage={() => t('autoCompleteTranslation.loading')}
      />
      <InputGroup.Text
        style={{ height: '29px', borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderLeft: 'none' }}
      >
        <FaSearch />
      </InputGroup.Text>
    </div>
  );
};

export default Autocomplete;
