import { Form } from 'react-bootstrap';
import { styles } from '../../styles';
import { PDF_COL_STYLE_TABLE_BY_TYPE } from 'features/PDF/constants/style';
import dayjs from 'dayjs';
import './style.scss';
import SelectMenuColor from '../SelectMenuColor/SelectMenuColor';

const ApproveSelect = ({ record, onApprove, isSelected, style, disabled }: any) => {
  return (
    <div
      style={{
        ...styles.col,
        ...styles.colApprove,
        padding: 0,
        backgroundColor: 'white',
        width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%',
        position: 'relative',
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <SelectMenuColor isSelected={isSelected} />
      {record.isHidden || record.isChange || record?.isApprove ? (
        <div style={{ ...style }}>
          <Form.Check
            className="approve-checkbox-menu"
            inline
            type="checkbox"
            style={{ margin: '0 6px', ...style }}
            checked={record?.isApprove}
            onChange={(e) => {
              onApprove(e, record);
            }}
            disabled={disabled}
          />
          {record?.isApprove ? (
            <div>
              <div>{record.approverName}</div>
              <div>{`${dayjs(record.approveDate).format('YYYY/MM/DD HH:mm')}`}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const ConfirmSelect = ({ record, onConfirm, isSelected, style, disabled }: any) => {
  return (
    <div
      style={{
        ...styles.col,
        ...styles.colConfirm,
        padding: 0,
        backgroundColor: 'white',
        width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%',
        position: 'relative',
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <SelectMenuColor isSelected={isSelected} />
      {record.isHidden || record.isChange || record?.isConfirm ? (
        <div style={{ ...style }}>
          <Form.Check
            className="approve-checkbox-menu"
            inline
            type="checkbox"
            style={{ margin: '0 6px', ...style }}
            checked={record?.isConfirm}
            onChange={(e) => {
              onConfirm(e, record);
            }}
            disabled={disabled}
          />
          {record?.isConfirm ? (
            <div>
              <div>{record.confirmerName}</div>
              <div>{`${dayjs(record.confirmDate).format('YYYY/MM/DD HH:mm')}`}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export { ApproveSelect, ConfirmSelect };
