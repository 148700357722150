import { Button, Col, Modal, Row } from 'react-bootstrap';
import { LiaTimesSolid } from 'react-icons/lia';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Autocomplete from 'components/common/Autocomplete';
import { ReplaceItemTable } from './replaceItemTable';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DEFAULT_END_DATE } from '../constants/date';
import ItemReplacementService from '../service';
import dayjs from 'dayjs';
import { ELEMENT_PREFIX, PROCESS_PRODUCT_PREFIX, PURCHASE_PRODUCT_PREFIX } from '../constant';

interface ReplaceItemModalProps {
  onClose: () => void;
  onReplace: (data: any) => void;
  selectedProduct: any;
  selectedItems: any[];
}

export interface ReplaceItemModalForm {
  selected_end_date: Date;
  replace_start_date?: Date;
  replace_end_date?: Date;
}

const ReplaceItemModal = ({ onClose, onReplace, selectedProduct, selectedItems }: ReplaceItemModalProps) => {
  const { t } = useTranslation();

  // state
  const [filter, setFilter] = useState<{ selectedProduct: any; business: string[] }>({
    selectedProduct: {},
    business: [],
  });
  const isChosenReplaceItem = filter.selectedProduct.code !== undefined;
  const selectedProductPrefix = selectedProduct.code[0];

  const searchFilterSelection = async (inputValue: string) => {
    const res = await fetchSearchProduct(inputValue);
    const mappedRes = res?.data.map((item: any) => {
      const code = item.purchase_item_cd || item.process_item_cd || item.element_cd;
      const name = item.purchase_item_name || item.process_item_name || item.element_name;
      const value = code;
      const label = `${code} - ${name}`;
      return {
        ...item,
        code,
        label,
        value,
        name,
      };
    });

    const filteredRes = mappedRes?.filter((item: any) => item.code !== selectedProduct.code);

    let prefixFilter = filteredRes;
    if (selectedProductPrefix === ELEMENT_PREFIX) {
      prefixFilter = filteredRes?.filter((item: any) => item.code[0] === ELEMENT_PREFIX);
    }
    if (selectedProductPrefix === PURCHASE_PRODUCT_PREFIX || selectedProductPrefix === PROCESS_PRODUCT_PREFIX) {
      prefixFilter = filteredRes?.filter(
        (item: any) => item.code[0] === PURCHASE_PRODUCT_PREFIX || item.code[0] === PROCESS_PRODUCT_PREFIX
      );
    }

    return prefixFilter;
  };

  const fetchSearchProduct = async (inputValue: any) => {
    try {
      const res = await ItemReplacementService.getAllItems({ searchText: inputValue });
      return res;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const loadOptions = async (inputValue: string, callback: (options: any[]) => void) => {
    const res = await searchFilterSelection(inputValue);
    callback(res || []);
  };

  const handleSelectItem = (value: any) => {
    const item = value;
    setFilter({
      ...filter,
      selectedProduct: item,
    });
    setValue('selected_end_date', null);
  };

  const schema = yup.object({
    selected_end_date: yup
      .date()
      .nullable()
      .when((replace_end_date, schema) => {
        if (!isChosenReplaceItem) return schema.required(t('ItemReplacementScreen.warn_field_required'));
        return schema;
      }),
    replace_start_date: yup.date().when((replace_end_date, schema) => {
      if (isChosenReplaceItem) return schema.required(t('ItemReplacementScreen.warn_field_required'));
      return schema;
    }),
    replace_end_date: yup.date().when((replace_end_date, schema) => {
      if (isChosenReplaceItem) return schema.required(t('ItemReplacementScreen.warn_field_required'));
      return schema;
    }),
  });

  // react-hook-form setup
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      replace_end_date: DEFAULT_END_DATE,
    },
    mode: 'all',
  });

  const replaceStartDateValue = watch('replace_start_date');
  const replaceEndDateValue = watch('replace_end_date');
  const selectedEndDateValue = watch('selected_end_date');

  const onSubmit = async (data: any) => {
    const payload = {
      replace_start_date: dayjs(data.replace_start_date).format('YYYY-MM-DD'),
      replace_end_date: dayjs(data.replace_end_date).format('YYYY-MM-DD'),
      selected_end_date: data.selected_end_date ? dayjs(data.selected_end_date).format('YYYY-MM-DD') : null,
      replace_product: filter.selectedProduct,
    };
    onReplace(payload);
  };

  const handleOnSubmit = (event: any) => {
    return handleSubmit((data) => onSubmit(data))(event);
  };

  return (
    <Modal show size="lg" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
      <Modal.Header className="border-bottom-0 justify-content-end">
        <Button variant="primary" className="btn--auto" onClick={onClose}>
          <LiaTimesSolid size={16} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row className="align-items-center mb-3 gap-4">
            {/* Search filter  */}
            <Row>
              <Col xs="auto">
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Autocomplete
                      placeholder={t(`ItemReplacementScreen.name_code_search`)}
                      loadOptions={loadOptions}
                      onChange={handleSelectItem}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </div>

        <div className="text-primary fw-bold">{t(`ItemReplacementScreen.replace_item_modal_note`)}</div>

        {/* Table */}
        <ReplaceItemTable
          selectedItems={selectedItems}
          chosenProduct={selectedProduct}
          replaceProduct={filter.selectedProduct}
          setValue={setValue}
          errors={errors}
          date={{
            replaceStartDateValue,
            replaceEndDateValue,
            selectedEndDateValue,
          }}
        />

        <div className="d-flex justify-content-center" style={{ marginTop: '12px' }}>
          <Button type="submit" onClick={handleOnSubmit}>
            {t('ItemReplacementScreen.execution')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReplaceItemModal;
