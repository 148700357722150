import dayjs from 'dayjs';
import { ApiResponse, CommonResponse } from '../../../../models/common';
import axiosClient from '../../../../utils/axiosClient';
import { MenuStructureSetting, MenuStructureSettingAdd } from '../models';
import { MenuStructureSettingListDataResponse } from '../models/MenuStructureSettingList';

const ROOT_API_ROUTE = 'menu-masters';

const MenuStructureSettingService = {
  getAll(payload: any): Promise<CommonResponse<MenuStructureSettingListDataResponse>> {
    const page = `?page=${payload.page}`;
    const business = `&business=${payload.business ?? ''}`;
    const searchText = `&search_text=${payload.searchText}`;
    const menuCategory = `&menu_category=${payload.menuCategory ?? ''}`;
    const notice = `&notice=${payload.notice}`;
    const menuStatus = `&menu_status=${payload.status}`;
    const period = `&period=${payload.applicablePeriod}`;
    const revisionDate = `&revision_date=${
      payload.revisionDate ? dayjs(payload.revisionDate).format('YYYY-MM-DD') : ''
    }`;

    let url = `${ROOT_API_ROUTE}${page}${business}${searchText}${menuCategory}${notice}${menuStatus}${period}${revisionDate}`;
    return axiosClient.get(url);
  },
  getItem(id: string): Promise<CommonResponse<Array<MenuStructureSetting>>> {
    const url = `${ROOT_API_ROUTE}/${id}`;
    return axiosClient.get(url);
  },
  update(id: string, payload: Partial<MenuStructureSetting>): Promise<ApiResponse> {
    const url = `${ROOT_API_ROUTE}/${id}`;
    return axiosClient.patch(url, payload);
  },
  updateStatus(id: string, payload: { status: string; menu_name?: string }): Promise<ApiResponse> {
    const url = `${ROOT_API_ROUTE}/${id}`;
    return axiosClient.patch(url, payload);
  },
  add(payload: Partial<MenuStructureSettingAdd>): Promise<ApiResponse> {
    const url = `${ROOT_API_ROUTE}`;
    return axiosClient.post(url, payload);
  },
};

export default MenuStructureSettingService;
