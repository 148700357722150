import { CustomInputDatePicker } from 'components/common/CustomInputDatePicker';
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

function DemoFormPage() {
  const notify = (type: string) => {
    switch (type) {
      case 'info':
        const htmlContent = '<div><strong>Hello</strong>, <br/>this is an <em>HTML</em> toast!</div>';
        toast.info(<div dangerouslySetInnerHTML={{ __html: htmlContent }} />);
        // toast.info('Wow so easy!');
        break;
      case 'success':
        toast.success('Data saved OK!');
        break;
      case 'error':
        toast.error('Oops, something went wrong!');
        break;
      default:
        toast('Default text');
    }
  };

  return (
    <div className="page-content d-flex flex-column">
      <h1>Demo Form Controls</h1>
      <hr />
      <div className="mb-5">
        <div className="mb-3">
          <h4>Buttons</h4>
        </div>
        <div className="d-flex gap-3 mb-3">
          <Button>button primary</Button>
          <Button variant="secondary">Button Secondary</Button>
          <Button variant="primary" className="btn--auto">
            <FaTimes />
          </Button>
          <Button onClick={() => notify('info')}>Show Toast Info</Button>
          <Button onClick={() => notify('success')}>Show Toast Success</Button>
          <Button onClick={() => notify('error')}>Show Toast Error</Button>
        </div>
        <div className="d-flex gap-3">
          <Button className="btn--green">.btn--green</Button>
          <Button className="btn--violet">.btn--violet</Button>
          <Button className="btn--yellow">.btn--yellow</Button>
          <Button className="btn--red">.btn--red</Button>
          <Button className="btn--white">.btn--white</Button>
          <Button className="btn--blue">.btn--blue</Button>
          <Button className="btn--orange">.btn--orange</Button>
        </div>
      </div>

      {/* HelperText */}
      <div className="mb-5">
        <div className="mb-3">
          <h4>HelperText</h4>
        </div>
        <div className="d-flex gap-4">
          <div className="text-primary fw-bold">※栄養価は総量 (.text-primary)</div>
          <div className="text-danger fw-bold">変更有 (.text-danger)</div>
        </div>
      </div>

      {/* Demo Filter of A008_1 (Horizontal Form) */}
      <div className="mb-5">
        <div className="mb-3">
          <h4>Demo Filter of A008_1 (Horizontal Form)</h4>
        </div>
        <Row className="align-items-center mb-3 gap-4">
          <Col xs="2">
            <InputGroup>
              <FormControl placeholder="Search" aria-label="Search" />
              <InputGroup.Text>
                <FaSearch />
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Label className="mb-0 fw-bold" htmlFor="selectDepartment">
                  部品分類
                </Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Select id="selectDepartment" style={{ width: '15rem' }}>
                  <option>一品料理1</option>
                  <option>一品料理2</option>
                  <option>一品料理3</option>
                  <option>一品料理4</option>
                  <option>一品料理5</option>
                </Form.Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3 gap-4">
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Label className="fw-bold mb-0">ステータス</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="全て"
                  name="group1"
                  type="radio"
                  id="group1-item-1"
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="確認待ち"
                  name="group1"
                  type="checkbox"
                  id="group1-item-2"
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="承認待ち"
                  name="group1"
                  type="radio"
                  id="group1-item-3"
                />
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Label className="fw-bold mb-0">お知らせ</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="全て"
                  name="group2"
                  type="radio"
                  id="group2-item-1"
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="変更ありのみ"
                  name="group2"
                  type="radio"
                  id="group2-item-2"
                />
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Label className="fw-bold mb-0">適用期間</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="全て"
                  name="group3"
                  type="radio"
                  id="group3-item-1"
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="有効期間のみ"
                  name="group3"
                  type="radio"
                  id="group3-item-2"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* Vertical Form */}
      <div>
        <div className="mb-3">
          <h4>Vertical Form</h4>
        </div>
        <Form style={{ maxWidth: '480px' }}>
          <Form.Group as={Row} className="mb-3" controlId="textbox">
            <Form.Label column sm="4" className="text-end">
              TextBox
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" placeholder="Enter text" isInvalid={true} />
              <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="textarea">
            <Form.Label column sm="4" className="text-end">
              Example textarea
            </Form.Label>
            <Col sm="8">
              <Form.Control as="textarea" rows={3} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="combobox">
            <Form.Label column sm="4" className="text-end">
              Example select
            </Form.Label>
            <Col sm="8">
              <Form.Select>
                <option>option 1</option>
                <option>option 2</option>
                <option>option 3</option>
                <option>option 4</option>
                <option>option 5</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="textnumeric">
            <Form.Label column sm="4" className="text-end">
              Example input numeric
            </Form.Label>
            <Col sm="8">
              <Form.Control type="number" placeholder="0" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="textnumeric">
            <Form.Label column sm="4" className="text-end">
              Example Date
            </Form.Label>
            <Col sm="8">
              <div style={{ maxWidth: '120px' }}>
                <ReactDatePicker
                  locale="ja"
                  selected={new Date()}
                  onChange={(date: Date) => {
                    // setFormSearchValue({ ...formSearchValue, dataDate: date });
                  }}
                  customInput={<CustomInputDatePicker />}
                  dateFormat="yyyy/MM/dd"
                  name="data_date"
                />
              </div>
            </Col>
          </Form.Group>
          <div className="text-center mt-4">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default DemoFormPage;
