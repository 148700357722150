import { Button, Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../Service';
import '../style.scss';
import {
  AllergyMaster,
  AllergyItem,
  NutritionMaster,
  PurchaseCalorieSetting,
  PurchaseCalorieSettingRequest,
} from '../models/PurchaseProcessedCalorieAllergySetting';
import {
  ITEMS_EQUIVALENT_TO_SPECIFIC_RAW_MATERIALS,
  SPECIFIC_RAW_MATERIALS,
  PENDING_CONFIRMATION,
  PENDING_APPROVAL,
  APPROVED,
} from '../constant';
import GroupButton, { HandleButtonClickParam } from '../components/ButtonApproveGroup';
import AllergyItemSelect from '../components/SelectItem';
import NutritionInputItem from '../components/InputItem';
import { toast } from 'react-toastify';
import { ApiError, ApiResponse } from 'models';
import { AxiosError } from 'axios';

const COLUMN_WIDTH = [50, 110, 100, 150, 200, 300];

function PurchaseProcessedCalorieAllergyDetailSetting() {
  const { t } = useTranslation();
  const { sms_cd }: { sms_cd: string } = useParams();
  const history = useHistory();

  //state
  const [data, setData] = useState<PurchaseCalorieSetting>();
  const [nutritions, setNutritions] = useState<NutritionMaster[]>([]);
  const [allergyUnitItems, setAllergyUnitItems] = useState<AllergyItem[]>([]);
  const [allergyMasterList, setAllergyMasterList] = useState<AllergyMaster[]>([]);

  const [status, setStatus] = useState('');
  const [lastUpdater, setLastUpdater] = useState('');

  // constant
  const apiCalls = {
    [HandleButtonClickParam.CONFIRMATION_REQUEST]: api.confirmRequest,
    [HandleButtonClickParam.APPROVAL_REQUEST]: api.approveRequest,
    [HandleButtonClickParam.ADMIT]: api.admitRequest,
    [HandleButtonClickParam.REMAND]: api.cancelRequest,
  };

  //useEffect
  useEffect(() => {
    fetchData();
    if (nutritions.length === 0) {
      fetchNutritions();
    }
    if (allergyUnitItems.length === 0) {
      fetchAllergyUnits();
    }
    if (allergyMasterList.length === 0) {
      fetchAllergyMaster();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function
  const fetchNutritions = async () => {
    try {
      const res = await api.getAllNutritions();
      setNutritions(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchAllergyUnits = async () => {
    try {
      const res = await api.getAllItemUnitAllergies();
      setAllergyUnitItems(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchAllergyMaster = async () => {
    try {
      const res = await api.getAllItemMasterAllergies();
      setAllergyMasterList(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await api.getById(sms_cd);
      setStatus(response.status);
      if (response.status === PENDING_CONFIRMATION) {
        setLastUpdater(response.applicant_cd);
      } else if (response.status === PENDING_APPROVAL) {
        setLastUpdater(response.verifier_cd);
      }
      setData(response);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const confirmRequestStatus = async (
    data: PurchaseCalorieSettingRequest,
    callback: (data: PurchaseCalorieSettingRequest) => Promise<ApiResponse>
  ) => {
    try {
      const response = await callback(data);
      if (response) {
        toast.success(response.message);
        fetchData();
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      const res = (error as AxiosError<ApiError>)?.response?.data;
      const status = (error as AxiosError<ApiError>)?.response?.status;
      if (res && res.message && status !== 500) {
        toast.error(res.message);
      }
    }
  };

  const approveRequestStatus = async (
    data: { sms_cd: string },
    callback: (data: { sms_cd: string }) => Promise<ApiResponse>
  ) => {
    try {
      const response = await callback(data);
      if (response) {
        toast.success(response.message);
        fetchData();
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      const res = (error as AxiosError<ApiError>)?.response?.data;
      if (res) {
        toast.error(res.message);
      }
    }
  };

  const handleApproveButtonClick = (buttonType: HandleButtonClickParam) => {
    if (!data) return;
    const dataUpdate = {
      supplier_item_cd: data.supplier_item_cd,
      status: status,
      remarks: data.process_item ? data.process_item.remarks : data.purchase_item.remarks,
      purchase_nutrition_master: data.purchase_nutrition_master
        .filter((p) => p.change_flg)
        .map((p) => ({
          id: p.id,
          nutrition_amount: p.nutrition_amount,
          nutrition_cd: p.nutrition_cd,
          change_flg: p.change_flg,
        })),
      purchase_item_allergy_master: data.purchase_item_allergy_master
        .filter((p) => p.change_flg)
        .map((p) => ({
          id: p.id,
          allergy_master: p.allergy_master,
          allergy_item_master: p.allergy_item_master,
          change_flg: p.change_flg,
        })),
    };

    if (buttonType === HandleButtonClickParam.CONFIRMATION_REQUEST) {
      const apiCall = apiCalls[buttonType];
      confirmRequestStatus(dataUpdate, async () => await apiCall(dataUpdate));
    } else {
      const apiCall = apiCalls[buttonType];
      approveRequestStatus(
        { sms_cd: data.supplier_item_cd },
        async () => await apiCall({ sms_cd: data.supplier_item_cd })
      );
    }
  };

  // handle change nutrition
  const handleChangeNutritionAmount = (event: React.ChangeEvent<HTMLInputElement>, nutritionCd: number) => {
    const { id, value } = event.target;
    let processedValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    const amount = parseFloat(processedValue) || 0;
    setData(
      data && {
        ...data,
        purchase_nutrition_master: data.purchase_nutrition_master.map((nutrition) =>
          nutrition.id === Number(id) ? { ...nutrition, nutrition_amount: amount, change_flg: true } : nutrition
        ),
      }
    );

    const isExist = data?.purchase_nutrition_master.some((nutrition) => nutrition.id === Number(id));
    if (!isExist) {
      setData(
        data && {
          ...data,
          purchase_nutrition_master: [
            ...data.purchase_nutrition_master,
            {
              id: id ? Number(id) : 0,
              nutrition_amount: amount,
              nutrition_cd: nutritionCd,
              change_flg: true,
            },
          ],
        }
      );
    }
  };

  const handleChangeAllergyItem = (event: React.ChangeEvent<HTMLSelectElement>, allergyMaster: AllergyMaster) => {
    const { id, value } = event.target;
    if (parseInt(value) === 0) return false;

    const purchase_allergy_id = parseFloat(id);
    const allergy_item_cd = parseFloat(value);
    const allergy_item = allergyUnitItems.find((allergy) => allergy.allergy_item_cd === allergy_item_cd)!;
    setData(
      data && {
        ...data,
        purchase_item_allergy_master: data.purchase_item_allergy_master.map((purchased_allergy) =>
          purchased_allergy.id === purchase_allergy_id
            ? {
                ...purchased_allergy,
                change_flg: true,
                allergy_item_master: {
                  allergy_item_cd: allergy_item.allergy_item_cd,
                  allergy_item_name: allergy_item.allergy_item_name,
                },
              }
            : purchased_allergy
        ),
      }
    );
    const isExist = data?.purchase_item_allergy_master.some((allergy) => allergy.id === Number(id));
    if (!isExist) {
      setData(
        data && {
          ...data,
          purchase_item_allergy_master: [
            ...data.purchase_item_allergy_master,
            {
              id: id ? Number(id) : 0,
              allergy_master: allergyMaster,
              allergy_item_master: {
                allergy_item_cd: allergy_item.allergy_item_cd,
                allergy_item_name: allergy_item.allergy_item_name,
              },
              change_flg: true,
            },
          ],
        }
      );
    }
  };

  const handleChangeRemark = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData(
      data && {
        ...data,
        [data.process_item ? 'process_item' : 'purchase_item']: {
          ...(data.process_item ? data.process_item : data.purchase_item),
          remarks: event.target.value,
        },
      }
    );
  };

  return (
    <div className="page-content d-flex flex-column">
      {/* header */}
      <div className="pb-3 border-bottom">
        <Row className="align-items-center">
          <Col xs="4">
            <h4 className="mb-0 fw-bold">{t('CalorieAllergySettingDetail.head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <GroupButton status={status} lastUpdater={lastUpdater} handleButtonClick={handleApproveButtonClick} />
              <Button
                className="fs-5 fw-bold btn-sm"
                onClick={() => history.push('/calorie-management/purchase-processed-calorie-allergy-setting')}
              >
                {t('CalorieAllergySettingDetail.go_back')}
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* filter */}
      <div className="container-xl mt-3">
        <div className="align-items-center mb-5">
          <div className="card border-warning bg-main">
            <div className="card-body text-start">
              <div className="row py-1">
                <div className="col">
                  {t('CalorieAllergySettingDetail.sms_code')}: {data?.supplier_item_cd}
                </div>
                <div className="col">
                  {t('CalorieAllergySettingDetail.item_code')}:{' '}
                  {data?.process_item?.process_item_cd || data?.purchase_item.purchase_item_cd}
                </div>
                <div className="col">
                  {t('CalorieAllergySettingDetail.item_name')}：{' '}
                  {data?.process_item?.process_item_name || data?.purchase_item.purchase_item_name}
                </div>
                <div className="col">
                  {t('CalorieAllergySettingDetail.application_period')}：
                  {data?.process_item?.effective_start_date || data?.purchase_item.effective_start_date}&nbsp;～ &nbsp;
                  {data?.process_item?.effective_end_date || data?.purchase_item.effective_end_date}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {t('CalorieAllergySettingDetail.status')}: {data?.status}
                </div>
                <div className="col">
                  {t('CalorieAllergySettingDetail.last_update_by')}：{data?.applicant_name}
                </div>
                <div className="col">
                  {t('CalorieAllergySettingDetail.last_update_date')}：{data?.applied_date}
                </div>
                <div className="col">
                  {t('CalorieAllergySettingDetail.confirmed_by')} : {data?.verifier_name}
                </div>
                <div className="col">
                  {t('CalorieAllergySettingDetail.confirmed_date')}: {data?.verify_date}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* list */}
        <div className="mb-1 fw-bold">{t('CalorieAllergySettingDetail.nutrition_value_per_100g')}</div>
        <div className="table table-responsive mb-5">
          <div className="div-table fixsize-column purchase-detail-table">
            <div className="div-thead div-tr">
              {nutritions &&
                nutritions.map((nutrition, idx) => {
                  return (
                    <div
                      className="div-th d-flex align-items-center justify-content-center lh-1"
                      style={{ width: `${COLUMN_WIDTH[1]}px` }}
                      key={idx}
                    >
                      {nutrition.nutrition_name} <br></br> ({nutrition.nutrition_unit_name})
                    </div>
                  );
                })}
            </div>
            <div className="div-tr">
              {nutritions &&
                nutritions.map((nutrition, idx) => {
                  const nutritionMaster =
                    data && data.purchase_nutrition_master.find((item) => item.nutrition_cd === nutrition.nutrition_cd);

                  return (
                    <div
                      className="div-td d-flex align-items-center justify-content-center lh-2"
                      style={{
                        width: `${COLUMN_WIDTH[1]}px`,
                        background: nutritionMaster?.change_flg && data?.status !== APPROVED ? '#f8cecc' : '',
                      }}
                      key={idx}
                    >
                      <NutritionInputItem
                        text={nutritionMaster?.nutrition_amount.toString() || ''}
                        readonly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                        onChange={(e) => handleChangeNutritionAmount(e, nutrition.nutrition_cd)}
                        id={
                          nutritionMaster
                            ? nutritionMaster.id.toString()
                            : Math.floor(Math.random() * (-999 - 0) + 0).toString()
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* list */}
        <div className="mb-1 fw-bold">{t('CalorieAllergySettingDetail.specific_raw_materials')}</div>
        <div className="table table-responsive">
          <div className="div-table fixsize-column purchase-detail-table">
            <div className="div-thead div-tr">
              {allergyMasterList &&
                allergyMasterList
                  .filter((item) => item.type_name === SPECIFIC_RAW_MATERIALS)
                  .map((allergy, idx) => {
                    return (
                      <div
                        className="div-th d-flex align-items-center justify-content-center lh-1"
                        style={{ width: `${COLUMN_WIDTH[1]}px` }}
                        key={idx}
                      >
                        {allergy.allergy_name}
                      </div>
                    );
                  })}
            </div>
            <div className="div-tr">
              {allergyMasterList &&
                allergyMasterList
                  .filter((item) => item.type_name === SPECIFIC_RAW_MATERIALS)
                  .map((item, idx) => {
                    const purchaseAllergyItems = data?.purchase_item_allergy_master.find(
                      (allergy) => allergy.allergy_master.allergy_cd === item.allergy_cd
                    );
                    return (
                      <div
                        className="div-td d-flex align-items-center justify-content-center lh-2"
                        style={{
                          width: `${COLUMN_WIDTH[1]}px`,
                          background: purchaseAllergyItems?.change_flg && data?.status !== APPROVED ? '#f8cecc' : '',
                        }}
                        key={idx}
                      >
                        <AllergyItemSelect
                          value={purchaseAllergyItems?.allergy_item_master.allergy_item_cd || 0}
                          options={allergyUnitItems}
                          readonly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                          onChange={(e) => handleChangeAllergyItem(e, item)}
                          id={
                            purchaseAllergyItems
                              ? purchaseAllergyItems.id.toString()
                              : Math.floor(Math.random() * (-999 - 0) + 0).toString()
                          }
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
        <div className="mb-1 fw-bold">
          {t('CalorieAllergySettingDetail.items_equivalent_to_specific_raw_materials')}
        </div>
        <div className="table table-responsive">
          <div className="div-table fixsize-column purchase-detail-table">
            <div className="div-thead div-tr">
              {allergyMasterList &&
                allergyMasterList
                  .filter((item) => item.type_name === ITEMS_EQUIVALENT_TO_SPECIFIC_RAW_MATERIALS)
                  .map((allergy, idx) => {
                    return (
                      <div
                        className="div-th d-flex align-items-center justify-content-center lh-1"
                        style={{ width: `${COLUMN_WIDTH[1]}px` }}
                        key={idx}
                      >
                        {allergy.allergy_name}
                      </div>
                    );
                  })}
            </div>
            <div className="div-tr">
              {allergyMasterList &&
                allergyMasterList
                  .filter((item) => item.type_name === ITEMS_EQUIVALENT_TO_SPECIFIC_RAW_MATERIALS)
                  .map((item, idx) => {
                    const purchaseAllergyItems = data?.purchase_item_allergy_master.find(
                      (allergy) => allergy.allergy_master.allergy_cd === item.allergy_cd
                    );
                    return (
                      <div
                        className="div-td d-flex align-items-center justify-content-center lh-2"
                        style={{
                          width: `${COLUMN_WIDTH[1]}px`,
                          background: purchaseAllergyItems?.change_flg && data?.status !== APPROVED ? '#f8cecc' : '',
                        }}
                        key={idx}
                      >
                        <AllergyItemSelect
                          value={purchaseAllergyItems?.allergy_item_master.allergy_item_cd || 0}
                          options={allergyUnitItems}
                          readonly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                          onChange={(e) => handleChangeAllergyItem(e, item)}
                          id={
                            purchaseAllergyItems
                              ? purchaseAllergyItems.id.toString()
                              : Math.floor(Math.random() * (-999 - 0) + 0).toString()
                          }
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
        <div className="mb-1 fw-bold">{t('CalorieAllergySettingDetail.remark')}</div>
        <div className="row g-0 ">
          <div className="col-md-6 col-sm-12 bg-main p-1 text-center border">
            {t('CalorieAllergySettingDetail.table.remark')}
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md-6 col-sm-12 border border-top-0">
            <textarea
              rows={4}
              onChange={handleChangeRemark}
              value={data?.process_item?.remarks || data?.purchase_item.remarks}
              disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
              className="form-control border-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseProcessedCalorieAllergyDetailSetting;
