import dayjs from 'dayjs';
import { CommonResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { AllergyChartMaster } from './models';
import { MenuMaster } from '../MenuStructureSetting/models/MenuStructureSettingList';

const ROOT_API_ROUTE = 'allergy-chart-master';

const AllergyCaloryChartService = {
  getAll(payload: any): Promise<CommonResponse<Array<AllergyChartMaster>>> {
    const business_format_cd = `?business_format_cd=${payload.business}`;
    const show_more_date = `&show_more_date=${
      payload.applyShowMoreDate ? dayjs(payload.showMoreDate).format('YYYY-MM-DD') : ''
    }`;
    const url = `${ROOT_API_ROUTE}${business_format_cd}${show_more_date}`;
    return axiosClient.get(url);
  },
  create(payload: Partial<any>): Promise<CommonResponse> {
    const url = `${ROOT_API_ROUTE}`;
    return axiosClient.post(url, payload);
  },
  getDetail(id: string): Promise<CommonResponse<AllergyChartMaster>> {
    const url = `${ROOT_API_ROUTE}/${id}`;
    return axiosClient.get(url);
  },
  getDetailByMenus(payload: any): Promise<CommonResponse<Array<MenuMaster>>> {
    const menu_cds = `?menu_cds=${payload.menu_cds}`;
    const revision_date = `&revision_date=${
      payload.revision_date ? dayjs(payload.revision_date).format('YYYY-MM-DD') : ''
    }`;
    const url = `${ROOT_API_ROUTE}/by-menus${menu_cds}${revision_date}`;
    return axiosClient.get(url);
  },
  updateExportPDF(id: string): Promise<CommonResponse> {
    const url = `${ROOT_API_ROUTE}/export/${id}`;
    return axiosClient.patch(url);
  },
  update(id: string, payload: Partial<any>): Promise<CommonResponse> {
    const url = `${ROOT_API_ROUTE}/${id}`;
    return axiosClient.patch(url, payload);
  },
  updateStatus(id: string, payload: { status: string }): Promise<CommonResponse> {
    const url = `${ROOT_API_ROUTE}/${id}`;
    return axiosClient.patch(url, payload);
  },
  delete(id: string): Promise<CommonResponse> {
    const url = `${ROOT_API_ROUTE}/${id}`;
    return axiosClient.delete(url);
  },
};

export default AllergyCaloryChartService;
