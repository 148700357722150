interface TableStyleByTypeInterface {
  width: number | null;
  verticalHeader?: boolean;
  manualHeader?: boolean;
  backgroundColor?: string;
}
export interface ColTableStyleByTypeInterFace {
  select: TableStyleByTypeInterface;
  text: TableStyleByTypeInterface;
  number: TableStyleByTypeInterface;
  icon_mark: TableStyleByTypeInterface;
  text_mark:
    | TableStyleByTypeInterface & {
        specialTextStyle: {
          color: string;
          backgroundColor: string;
        };
        boldTextStyle: {
          fontWeight: 'black';
        };
      };
}

const PDF_COMPOSITION_LETTET_BLANK: number = 20;
const PDF_LETTET_BLANK: number = 10;
const PDF_FONT_SIZE: number = 5;
const PDF_LETTER_SPACING: number = 0;
const PDF_COL_HEIGHT: number = 12;
const PDF_SELECT_BRIGHT: number = 85;
const PDF_SELECT_BACKGOUND_COLOR: string = 'rgba(39, 230, 245, 0.1)';
const PDF_COMPOSITION_CHANGED_BACKGOUND_COLOR: string = '#FFFF66';
const PDF_HIDDEN_BRIGHT: number = 65;
const PDF_SUSPEND_BRIGHT: number = 80;

const PDF_BACKGROUND_COLOR = {
  red: 'red',
  green: 'green',
  blue: 'blue',
  lightYellow: 'lightYellow',
  yellow: 'yellow',
  lightOrange: 'lightOrange',
  orange: 'orange',
  white: 'white',
};

const PDF_TEXT_COLOR = {
  red: 'red',
};

const PDF_COLOR: { [key: string]: any } = {
  text: {
    [PDF_TEXT_COLOR.red]: '#ff0800',
  },
  background: {
    [PDF_BACKGROUND_COLOR.red]: '#ff9acd',
    [PDF_BACKGROUND_COLOR.green]: '#cdffcd',
    [PDF_BACKGROUND_COLOR.blue]: '#9acdff',
    [PDF_BACKGROUND_COLOR.lightYellow]: '#ffff9a',
    [PDF_BACKGROUND_COLOR.yellow]: '#ffff00',
    [PDF_BACKGROUND_COLOR.lightOrange]: '#ffcd00',
    [PDF_BACKGROUND_COLOR.orange]: '#ff9a00',
    [PDF_BACKGROUND_COLOR.white]: '#ffffff',
  },
};

const PDF_COL_STYLE_TABLE_BY_TYPE: any = {
  select: {
    width: 6,
  },
  text: {
    width: 24,
  },
  number: {
    width: 3,
    verticalHeader: true,
  },
  icon_mark: {
    width: null, // devide from the other
    verticalHeader: true,
  },
  text_mark: {
    width: 6,
    manualHeader: true,
    fontWeight: 'black',
    specialTextStyle: {
      color: PDF_COLOR['text']['red'],
      backgroundColor: PDF_COLOR['background']['yellow'],
    },
  },
};

export {
  PDF_LETTET_BLANK,
  PDF_FONT_SIZE,
  PDF_COL_STYLE_TABLE_BY_TYPE,
  PDF_COLOR,
  PDF_COL_HEIGHT,
  PDF_LETTER_SPACING,
  PDF_SELECT_BRIGHT,
  PDF_HIDDEN_BRIGHT,
  PDF_SELECT_BACKGOUND_COLOR,
  PDF_SUSPEND_BRIGHT,
  PDF_COMPOSITION_LETTET_BLANK,
  PDF_BACKGROUND_COLOR,
  PDF_COMPOSITION_CHANGED_BACKGOUND_COLOR,
};
