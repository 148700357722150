import { alertModalTranslationJP } from 'components/common/AlertModal/translation/locales';
import { autoCompleteTranslationJP } from 'components/common/Autocomplete/translation/locales';
import { confirmModalTranslationJP } from 'components/common/ConfirmModal/translation/locales';
import { statusHeaderTranslationJP } from 'components/common/StatusHeader/translation/locales';
import { approveGroupButtonTranslationJP } from 'features/ApproveGroupButton/translation/locales';
import { authFeatureTranslationJP } from 'features/Auth/translation/locales';
import { calorieManagementSettingTranslationJP } from 'features/CalorieManagement/CalorieAllergySetting/locales';
import { itemReplacementTranslationJP } from 'features/CalorieManagement/ItemReplacement/translate';
import { menuStructureSettingTranslationJP } from 'features/CalorieManagement/MenuStructureSetting/locales';
import { partsConfigurationJP } from 'features/CalorieManagement/PartsConfigurationSetting/translation/locales';
import { purchaseProcessedCalorieAllergySettingJP } from 'features/CalorieManagement/PurchaseProcessedCalorieAllergySetting/translation/locales';
import { allergyItemMasterTranslationJP } from 'features/MasterDataManagement/AllergyItemMaster/translation/locales';
import { allergyMasterTranslationJP } from 'features/MasterDataManagement/AllergyMaster/translation/locales';
import { elementClassificationMasterTranslationJP } from 'features/MasterDataManagement/ElementClassificationMaster/translation/locales';
import { menuCategoryMasterTranslationJP } from 'features/MasterDataManagement/MenuCategoryMaster/translation/locales';
import { nutritionMasterTranslationJP } from 'features/MasterDataManagement/NutritionMaster/translation/locales';
import { nutritionUnitMasterTranslationJP } from 'features/MasterDataManagement/NutritionUnitMaster/translation/locales';
import { compositionSearchModalTranslationJP } from 'features/SearchCompositionModal/translate';

const commonTranslationJP = {
  App: {
    app_name: 'アレルギー栄養システム',
    error: 'エラー',
    info: '情報',
    warning: '警告',
    confirm: '確認',
    loading: '読み込み中...',
    change: '変更',
    success: '成功',
    unexpected_error: '処理に失敗しました',
  },
  ...statusHeaderTranslationJP,
  ...confirmModalTranslationJP,
  ...alertModalTranslationJP,
  ...approveGroupButtonTranslationJP,
};

export const translationJP = {
  ...commonTranslationJP,
  ...authFeatureTranslationJP,
  ...menuStructureSettingTranslationJP,
  ...allergyMasterTranslationJP,
  ...allergyItemMasterTranslationJP,
  ...itemReplacementTranslationJP,
  ...calorieManagementSettingTranslationJP,
  ...nutritionUnitMasterTranslationJP,
  ...elementClassificationMasterTranslationJP,
  ...nutritionMasterTranslationJP,
  ...menuCategoryMasterTranslationJP,
  ...purchaseProcessedCalorieAllergySettingJP,
  ...partsConfigurationJP,
  ...compositionSearchModalTranslationJP,
  ...autoCompleteTranslationJP,
};
