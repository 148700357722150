import { Button, Form, Table } from 'react-bootstrap';
import { CALORIE_TABLE_HEADER } from '../constants/tableHeader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

const APPROVED = '承認済み';

const CalorieTable = ({
  charts,
  onSelect,
  selectedIds,
  onRemove,
}: {
  charts: any[];
  onSelect: (id: number) => void;
  selectedIds: number[];
  onRemove: (allergy_chart_cd: string) => void;
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleRemove = (allergy_chart_cd: string) => {
    onRemove(allergy_chart_cd);
  };
  return (
    <div className="sticky-table flex-grow-1 flex-shrink-1">
      <Table>
        <thead>
          <tr>
            {CALORIE_TABLE_HEADER.map((header, idx) => (
              <th style={{ width: header.width }} key={idx}>
                {t(`${header.title}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {charts.map((chart, idx) => {
            const isChange = chart?.change_flag;
            return (
              <tr key={idx}>
                <td>
                  {chart.status === APPROVED ? (
                    <Form.Check
                      className="anra-checkbox d-flex justify-content-center"
                      label=""
                      type="checkbox"
                      onChange={() => onSelect(chart.allergy_chart_cd)}
                      checked={selectedIds.includes(chart.allergy_chart_cd)}
                    />
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  {isChange ? (
                    <div className="change-dot-container">
                      <div className="change-dot"></div>
                    </div>
                  ) : (
                    <></>
                  )}
                </td>
                <td>{chart.status}</td>
                <td>{chart.business_format_master?.name}</td>
                <td>{chart.title}</td>
                <td>{chart.revision_date ? dayjs(chart.revision_date).format('YYYY/MM/DD') : ''}</td>
                <td>{chart.updated_date ? dayjs(chart.updated_date).format('YYYY/MM/DD HH:mm') : ''}</td>
                <td>{chart.approve_date ? dayjs(chart.approve_date).format('YYYY/MM/DD HH:mm') : ''}</td>
                <td>{chart.export_date ? dayjs(chart.export_date).format('YYYY/MM/DD') : ''}</td>
                <td>{chart.remarks}</td>

                <td>
                  {chart.status === APPROVED ? (
                    <Button
                      onClick={() =>
                        history.push(`/calorie-management/calorie-allergy-chart-setting/copy/${chart.allergy_chart_cd}`)
                      }
                    >
                      {t('CalorieManagementSettingScreen.revision')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        style={{ marginRight: '5px' }}
                        onClick={() =>
                          history.push(
                            `/calorie-management/calorie-allergy-chart-setting/edit/${chart.allergy_chart_cd}`
                          )
                        }
                      >
                        {t('CalorieManagementSettingScreen.edit')}
                      </Button>
                      <Button onClick={() => handleRemove(chart.allergy_chart_cd)}>
                        {' '}
                        {t('CalorieManagementSettingScreen.delete')}
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default CalorieTable;
