export interface ToastContentItem {
  message: string;
  success: boolean;
}

const generateToastContent = (listContent: ToastContentItem[], title: string) => {
  return (
    <div>
      <p
        style={{
          color: 'black',
          fontSize: 16,
          fontWeight: 'bold',
        }}
      >
        {title}
      </p>
      {listContent.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 8,
          }}
        >
          <span style={{ marginRight: 8 }}>{item.success ? '✅' : '❌'}</span>
          <span>{item.message}</span>
        </div>
      ))}
    </div>
  );
};

export default generateToastContent;
