export const menuStructureSettingTranslationJP = {
  MenuStructureSettingScreen: {
    edit_head_title: 'メニュー構成設定（更新）',
    create_head_title: 'メニュー構成設定（新規登録）',
    list_head_title: 'メニュー構成設定',
    menu: 'メニューコード',
    name: '名称',
    menu_category: 'メニューカテゴリ',
    suspension_date: '利用停止日',
    sort_key: 'ソートキー',
    warn_field_required: 'この項目は必須です',
    composition: '構成',
    delete: '削除',
    additional: '追加',
    change: '変更有',
    code: '構成コード',
    usage_amount: '使用量',
    display_group: '表示グループ',
    apply_start_date: '適用開始日',
    apply_end_date: '適用終了日',
    remark: '備考',
    applicable_date: '適用日',
    apply: '絞込',
    clear: '解除',
    display_menu: 'メニュー内訳をカロアレ表に表示する',
    back: '戻る',
    register: '登録',
    business: '業態',
    edit: '編集',
    status: 'ステータス',
    copy_and_register: 'コピーして新規登録',
    sign_up: '新規登録',
    name_code_search: '名称・コード検索',
    all: '全て',
    confirming: '確認待ち',
    pending_approval: '承認待ち',
    only_changed: '変更ありのみ',
    applicable_period: '適用期間',
    only_validity: '有効期間のみ',
    icon_note: '※●…アレルゲンを含む　▲…コンタミの可能性あり　',
    nutritional_note: '※栄養価は総量',
    notice: 'お知らせ',
    warning_no_composition: '構成品が必須です。',
    unregister: '未登録',
    negative_number: 'ゼロ以上の数字が必須です',
    one_degit_after_comma: '小数点一つが必須です',
  },
};
