import { ShichirinboLogoIcon } from 'assets/logos/shichirinbo';
import { YellowFrame } from './YellowFrame';

const INFO_LEFT_MARGIN = 16;

const ShichirinboTemplate = ({ onDateChange, date, isAllowedEdit }: any) => {
  return (
    <div>
      <div
        style={{
          margin: '10 0',
          width: '100%',
          fontWeight: 'light',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 2 }}>
          <ShichirinboLogoIcon height={100} width={200} />
        </div>

        {/* text in black frame */}
        <div
          style={{
            width: '310px',
            fontWeight: 'bold',
            padding: '1px 2px',
            marginBottom: 3,
            border: 'solid 1px black',
          }}
        >
          <div>食品衛生法に基づき、下記について表示をしております</div>
        </div>

        {/* 1 sentance */}
        <div style={{ marginLeft: INFO_LEFT_MARGIN }}>
          <div>
            <p style={{ marginBottom: 1 }}>
              アレルゲン27品目を含む商品「○」、原材料にコンタミの可能性があるものは「▲」を表示しております。
              <strong> 詳細についてはアレルゲンTOPページをご確認ください。</strong>
            </p>
          </div>

          {/* 2 sentance */}
          <div style={{ marginBottom: 5 }}>
            栄養成分値は日本食品標準成分表による数値および、検査機関での分析値を元に算出しております。卓上のタレをかけた場合の栄養成分はこちらに表示しておりません。あらかじめご了承ください。
          </div>

          {/* 3 sentance */}
          <div style={{ marginBottom: 2 }}>
            ★該当アレルゲンを含む商品として「○」表記のあるものから、下記のように注釈を入れております。
          </div>
        </div>

        {/* yello frame */}
        <YellowFrame />

        {/* 4 sentance */}
        <div>
          ※店舗によりメニュー、原材料が異なる場合がございます。　また、同一メニューであっても原材料は都合により変わることがあります。ご利用の際は最新のものをご確認くださいますようお願い致します。
        </div>
      </div>
    </div>
  );
};

export { ShichirinboTemplate };
