export const purchaseProcessedCalorieAllergySettingJP = {
  CalorieAllergySetting: {
    head_title: '仕入品・加工品カロアレ設定',
    update: '更新',
    register: '新規登録',
    delete: '削除',
    allergy_item_name: '名称',
    description: '説明',
    priority: '優先度',
    display_order: '表示順',
    pdf_format: 'カロアレ表書式',
    add: '登録',
    hidden: '非表示',
    master_data_integration: 'マスタデータ連携',
    edit: '編集',
    go_back: '戻る',
    status: 'ステータス',
    pending_confirmation: '確認待ち',
    pending_approval: '承認待ち',
    display_all_items_in_the_purchase_master: '仕入品マスタを全件表示する',
    note_1: '※●…アレルゲンを含む　▲…コンタミの可能性あり',
    note_2: '※栄養価は総量',
    name_or_code_search: '名称・コード検索',
    table: {
      selection: '選択',
      status: 'ステータス',
      sms_code: 'SMSコード',
      element_code: '仕入品・加工品<br/>コード',
      name: '名称',
      specific_raw_materials: '特定原材料',
      items_equivalent_to_specific_raw_materials: '特定原材料に準ずる品目',
      usage_classification: '利用区分',
      confirmed_by: '確認者',
      confirmed_date: '確認日',
      last_update_date: '最終更新日',
      last_update_by: '最終更新者',
    },
    DialogConfirm: {
      btn_cancel: 'キャンセル',
      btn_ok: 'OK',
      title: '確認',
      message: '非表示にしますか？',
    },
  },
  CalorieAllergySettingDetail: {
    head_title: '仕入品（加工品）カロアレ設定',
    confirm_request: '確認依頼',
    approval_request: '承認依頼',
    approval: '承認',
    remand: '差戻',
    go_back: '戻る',
    status: 'ステータス',
    sms_code: 'SMSコード',
    item_code: '仕入品コード',
    item_name: '名称',
    application_period: '適用期間',
    last_update_date: '更新日時',
    last_update_by: '最終更新者',
    confirmed_by: '確認者',
    confirmed_date: '確認日時',
    nutrition_value_per_100g: '■栄養価（１００ｇあたり）',
    specific_raw_materials: '■特定原材料',
    items_equivalent_to_specific_raw_materials: '■特定原材料に準ずる品目',
    remark: '■備考',
    table: {
      remark: '備考',
    },
  },
};
