export const calorieManagementSettingTranslationJP = {
  CalorieManagementSettingScreen: {
    edit_head_title: 'カロアレ表設定・出力編集',
    list_head_title: 'カロアレ表設定・出力',
    output: '出力',
    new_register: '新規登録',
    back: '戻る',
    show_more: '以降を表示する',
    menu: 'メニューコード',
    name: '名称',
    menu_category: 'メニューカテゴリ',
    suspension_date: '利用停止日',
    sort_key: 'ソートキー',
    warn_field_required: 'この項目は必須です',
    composition: '構成',
    delete: '削除',
    additional: '追加',
    change: '変更有',
    code: '構成コード',
    usage_amount: '使用量',
    display_group: '表示グループ',
    apply_start_date: '適用開始日',
    apply_end_date: '適用終了日',
    remark: '備考',
    applicable_date: '適用日',
    apply: '絞込',
    clear: '解除',
    display_menu: 'メニュー内訳をカロアレ表に表示する',
    register: '登録',
    business: '業態',
    edit: '編集',
    status: 'ステータス',
    copy_and_register: 'コピーして新規登録',
    sign_up: '新規登録',
    name_code_search: '名称・コード検索',
    all: '全て',
    confirming: '確認待ち',
    pending_approval: '承認待ち',
    only_changed: '変更ありのみ',
    applicable_period: '適用期間',
    only_validity: '有効期間のみ',
    execution: '実行',
    template: 'テンプレート',
    preview: 'プレビュー',
    apply_layout: 'レイアウト適用',
    hide_menu: '非表示に変更',
    add_menu: 'メニュー追加',
    add_category: '分類追加',
    save: '保存',
    add: '追加',
    category: 'メニューカテゴリ',
    hierarchy: 'メニュー階層',
    approval: '承認',
    confirmation: '確認',
    downloaded: 'ダウロードしました',
    revision: '改訂',
    draft: '変更は保存されていません',
  },
};
