export function dateToStringFormat(date: Date, format: 'YYYYMMDD' | 'YYYY/MM/DD' | 'YYYY-MM-DD' = 'YYYYMMDD') {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  switch (format) {
    case 'YYYYMMDD':
      return year + month + day;
    case 'YYYY/MM/DD':
      return [year, month, day].join('/');
    case 'YYYY-MM-DD':
      return [year, month, day].join('-');
    default:
      throw new Error('Invalid format');
  }
}

export function stringToDate(dateString: string, format: 'YYYYMMDD' | 'YYYY/MM/DD' | 'YYYY-MM-DD' = 'YYYYMMDD') {
  const [year, month, day] = dateString.split(format === 'YYYYMMDD' ? '' : format).map((s) => parseInt(s, 10));
  return new Date(year, month - 1, day);
}
