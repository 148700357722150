import { MouseEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Autocomplete from 'components/common/Autocomplete';
import { ItemTable } from './components/itemTable';
import ReplaceItemModal from './components/replaceItemModal';
import { createPortal } from 'react-dom';
import ItemReplacementService from './service';
import { toast } from 'react-toastify';

import { ELEMENT_PREFIX, MENU_PREFIX } from './constant';
import { useAppSelector } from 'app/hooks';
import {
  businessFormatMasterActions,
  selectBusinessFormatMasterData,
} from 'store/BusinessFormatMaster/businessFormatMasterSlice';
import { useDispatch } from 'react-redux';
import { ReplaceItem } from './models';

interface Filter {
  selectedProduct: any;
  business: string[];
}

const ItemReplacement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // state
  const [item, setItem] = useState<any>();
  const [filter, setFilter] = useState<Filter>({
    selectedProduct: {},
    business: [],
  });
  const [selectedItems, setSelectedItems] = useState<ReplaceItem[]>([]);
  const [isOpenReplaceItemModal, setIsOpenReplaceItemModal] = useState<boolean>();
  const isDisableRaplaceButton = selectedItems.length === 0 || filter.selectedProduct.code === undefined;
  const businessFormatMasterList = useAppSelector(selectBusinessFormatMasterData);

  useEffect(() => {
    if (businessFormatMasterList.length === 0) {
      dispatch(businessFormatMasterActions.fetchBusinessFormatMasterList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (businessFormatMasterList.length === 0) return;
    setFilter({
      ...filter,
      business: businessFormatMasterList.map((item) => item.business_format_cd),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessFormatMasterList]);

  const handleOpenReplacementModal = (event: MouseEvent<HTMLElement>) => {
    setIsOpenReplaceItemModal(true);
  };

  const handleCloseReplaceItemModal = () => {
    setIsOpenReplaceItemModal(false);
  };

  const fetchMenuAndElement = async (item: any) => {
    try {
      const res = await ItemReplacementService.getMenuAndElement({ code: item.code });
      return res.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchSearchProduct = async (inputValue: string) => {
    try {
      const res = await ItemReplacementService.getAllItems({ searchText: inputValue });
      return res;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    if (!item) return;
    const selectedMenuMasters = item?.menu_composition_masters
      ?.filter((item: any) => {
        return filter.business.includes(
          item.menu_master.menu_category_master?.business_format_master?.business_format_cd
        );
      })
      ?.map((menu: any) => {
        const code = menu.menu_master.menu_cd;
        const amount = menu.composition_quantity;
        const unit = menu.purchase_unit_master?.purchase_unit_cd || menu.element_unit_master?.element_unit_cd || null;
        const unitName = menu.purchase_unit_master?.name || menu.element_unit_master?.name;
        return {
          code,
          amount,
          unit,
          unitName,
        };
      });
    const selectedElementMasters = item?.element_composition_masters
      ?.filter((item: any) => {
        return filter.business.includes(item.element_master?.business_format_cd);
      })
      ?.map((element: any) => {
        const code = element.element_master.element_cd;
        const amount = element.composition_quantity;
        const unit =
          element.purchase_unit_master?.purchase_unit_cd || element.element_unit_master?.element_unit_cd || null;
        const unitName = element.purchase_unit_master?.name || element.element_unit_master?.name;
        return {
          code,
          amount,
          unit,
          unitName,
        };
      });
    const FilteredSelectedItems = new Set([
      ...(selectedMenuMasters || []),
      ...(selectedElementMasters || []),
      ...selectedItems,
    ]);
    setSelectedItems([...FilteredSelectedItems]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  // useEffect(() => {
  //   const fetchData = async (filter: Filter) => {
  //     if (filter?.selectedProduct?.code === undefined) return;
  //     const res = await fetchMenuAndElement(filter.selectedProduct, filter.business);
  //     setItem(res);
  //   };

  //   fetchData(filter);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filter.business]);

  const handleSelectItem = async (value: any) => {
    const item = value;
    setFilter({
      ...filter,
      selectedProduct: item,
    });
    setSelectedItems([]);
    const res = await fetchMenuAndElement(item);
    setItem(res);
  };

  const handleSelect = (item: ReplaceItem) => {
    if (selectedItems.some((selectedItem) => selectedItem.code === item.code)) {
      const newSelectedItems = selectedItems.filter((selectedItem) => selectedItem.code !== item.code);
      setSelectedItems([...newSelectedItems]);
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleAddBusiness = (e: any, value: any) => {
    if (filter.business.includes(value)) {
      const newBusinessFilter = filter.business.filter((item) => item !== value);
      setFilter({
        ...filter,
        business: [...newBusinessFilter],
      });

      // set select menu / element
      const selectedMenuMasters = item?.menu_composition_masters
        ?.filter((item: any) => {
          return item.menu_master.menu_category_master?.business_format_master?.business_format_cd === value;
        })
        ?.map((menu: any) => {
          return menu.menu_master.menu_cd;
        });
      const selectedElementMasters = item?.element_composition_masters
        ?.filter((item: any) => {
          return item.element_master?.business_format_cd === value;
        })
        ?.map((element: any) => {
          return element.element_master.element_cd;
        });
      const filteredRemoveSelectedItems = [...(selectedMenuMasters || []), ...(selectedElementMasters || [])];
      const filterRemainSelectedItems = selectedItems.filter((item: any) => {
        return !filteredRemoveSelectedItems.includes(item.code);
      });
      setSelectedItems([...filterRemainSelectedItems]);
    } else {
      setFilter({
        ...filter,
        business: [...filter.business, value],
      });

      // set select menu / element
      const selectedMenuMasters = item?.menu_composition_masters
        ?.filter((item: any) => {
          return item.menu_master.menu_category_master?.business_format_master?.business_format_cd === value;
        })
        ?.map((menu: any) => {
          const code = menu.menu_master.menu_cd;
          const amount = menu.composition_quantity;
          const unit = menu.purchase_unit_master?.purchase_unit_cd || menu.element_unit_master?.element_unit_cd || null;
          const unitName = menu.purchase_unit_master?.name || menu.element_unit_master?.name;
          return {
            code,
            amount,
            unit,
            unitName,
          };
        });
      const selectedElementMasters = item?.element_composition_masters
        ?.filter((item: any) => {
          return item.element_master?.business_format_cd === value;
        })
        ?.map((element: any) => {
          const code = element.element_master.element_cd;
          const amount = element.composition_quantity;
          const unit =
            element.purchase_unit_master?.purchase_unit_cd || element.element_unit_master?.element_unit_cd || null;
          const unitName = element.purchase_unit_master?.name || element.element_unit_master?.name;
          return {
            code,
            amount,
            unit,
            unitName,
          };
        });
      const filteredSelectedItems = new Set([
        ...(selectedMenuMasters || []),
        ...(selectedElementMasters || []),
        ...selectedItems,
      ]);
      setSelectedItems([...filteredSelectedItems]);
    }
  };

  const searchFilterSelection = async (inputValue: string) => {
    const res = await fetchSearchProduct(inputValue);
    const mappedRes = res?.data.map((item: any) => {
      const code = item.purchase_item_cd || item.process_item_cd || item.element_cd;
      const name = item.purchase_item_name || item.process_item_name || item.element_name;
      const value = code;
      const label = `${code} - ${name}`;
      return {
        ...item,
        code,
        label,
        value,
        name,
      };
    });
    return mappedRes;
  };

  const loadOptions = async (inputValue: string, callback: (options: any[]) => void) => {
    const res = await searchFilterSelection(inputValue);
    callback(res || []);
  };

  const handleReplace = async (data: any) => {
    const selectedMenus = selectedItems.filter((item: ReplaceItem) => item.code[0] === MENU_PREFIX);
    const selectedElements = selectedItems.filter((item: ReplaceItem) => item.code[0] === ELEMENT_PREFIX);
    const payload = {
      ...data,
      selected_elements: selectedElements,
      selected_menus: selectedMenus,
      old_product: filter.selectedProduct,
    };
    try {
      const res = await ItemReplacementService.update(payload);
      toast.success(res.message);
      handleCloseReplaceItemModal();
      // const resMenuAndElement = await fetchMenuAndElement(filter.selectedProduct);
      // setItem(resMenuAndElement);
    } catch (error: any) {
      const message = error.response.data.message;
      toast.error(message);
    }
  };

  return (
    <div className="page-content d-flex flex-column">
      {/* Modal */}
      {isOpenReplaceItemModal &&
        createPortal(
          <ReplaceItemModal
            selectedItems={selectedItems}
            selectedProduct={filter.selectedProduct}
            onClose={handleCloseReplaceItemModal}
            onReplace={handleReplace}
          />,
          document.body
        )}

      {/* Header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('ItemReplacementScreen.head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button onClick={handleOpenReplacementModal} disabled={isDisableRaplaceButton}>
                {t('ItemReplacementScreen.bulk_replacement')}
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* Filter form  */}
      <div className="py-3">
        <Row className="align-items-center mb-3 gap-4">
          {/* Search, select filter  */}
          <Row>
            <Col xs="auto">
              <Row className="align-items-center">
                <Col xs="auto">
                  <Autocomplete
                    placeholder={t(`ItemReplacementScreen.name_code_search`)}
                    loadOptions={loadOptions}
                    onChange={handleSelectItem}
                  />
                </Col>
                <Col xs="auto"></Col>
              </Row>
            </Col>
          </Row>

          {/* Button filter  */}
          <Row>
            <Col xs="auto">
              <Row className="align-items-center">
                <Form.Label column className="fw-bold mb-0 text-start">
                  {t('ItemReplacementScreen.business')}
                </Form.Label>
                <Col xs="auto">
                  {businessFormatMasterList.map((businessCheckbox, idx) => (
                    <Form.Check
                      key={idx}
                      className="anra-checkbox"
                      inline
                      type="checkbox"
                      id={`${businessCheckbox.business_format_cd}_${idx}`}
                      label={t(`${businessCheckbox.name}`)}
                      checked={filter.business.includes(businessCheckbox.business_format_cd)}
                      onChange={(e) => handleAddBusiness(e, businessCheckbox.business_format_cd)}
                    />
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </div>

      {/* Table */}
      <ItemTable
        menus={item?.menu_composition_masters || []}
        elements={item?.element_composition_masters || []}
        onSelect={handleSelect}
        selectedItems={selectedItems}
      />
    </div>
  );
};

export default ItemReplacement;
