import { yupResolver } from '@hookform/resolvers/yup';
import { NutritionUnitMaster } from 'models/masterdata';
import { RefObject, memo, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { NutritionUnitMasterListFormValues } from '..';

const COLUMN_WIDTH = [50, 220, 130];

export interface NutritionUnitMasterListProps {
  formRef: RefObject<HTMLFormElement>;
  data: NutritionUnitMaster[];
  onSubmitForm?: (formValues: NutritionUnitMasterListFormValues) => void;
}

function NutritionUnitMasterList({ formRef, data, onSubmitForm }: NutritionUnitMasterListProps) {
  const { t } = useTranslation();

  // schema validation
  const schema = yup.object({
    data: yup
      .array(
        yup.object({
          nutrition_unit_cd: yup.number().required(),
          nutrition_unit_name: yup.string().required(t('NutritionUnitMaster.warn_field_required')),
          display_order: yup
            .number()
            .transform((value, originalValue) => {
              if (typeof originalValue === 'string' && originalValue.trim() === '') {
                return undefined;
              }
              return value;
            })
            .min(1, t('NutritionUnitMaster.warn_number_required'))
            .integer(t('NutritionUnitMaster.warn_number_required'))
            .required(t('NutritionUnitMaster.warn_number_required')),
          deleted: yup.boolean().required(),
        })
      )
      .required(),
  });

  // form setup
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<NutritionUnitMasterListFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { data: data },
    mode: 'onSubmit',
  });
  const { fields } = useFieldArray({
    name: 'data',
    control,
    // keyName: 'key',
  });

  // side effects
  useEffect(() => {
    reset({
      data: data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //events
  function onSubmit(formValues: NutritionUnitMasterListFormValues) {
    onSubmitForm?.(formValues);
  }

  return (
    <div className="sticky-table flex-grow-1 flex-shrink-1">
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className="div-table fixsize-column">
          <div className="div-thead div-tr">
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[0]}px` }}>
              {t('NutritionUnitMaster.delete')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
              {t('NutritionUnitMaster.nutrition_unit_name')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
              {t('NutritionUnitMaster.display_order')}
            </div>
          </div>

          {fields.map((field, index) => {
            return (
              <div className="div-tr" key={field.nutrition_unit_cd}>
                <div
                  className="div-td d-flex align-items-center justify-content-center"
                  style={{ width: `${COLUMN_WIDTH[0]}px` }}
                >
                  <Form.Check
                    {...register(`data.${index}.deleted`)}
                    className="anra-checkbox d-flex justify-content-center"
                    label=""
                    type="checkbox"
                  />
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
                  <Form.Control
                    {...register(`data.${index}.nutrition_unit_name`)}
                    type="text"
                    placeholder=""
                    isInvalid={!!errors?.data?.[index]?.nutrition_unit_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.data?.[index]?.nutrition_unit_name?.message}
                  </Form.Control.Feedback>
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
                  <Form.Control
                    {...register(`data.${index}.display_order`)}
                    type="number"
                    placeholder=""
                    min={1}
                    className="text-end"
                    isInvalid={!!errors?.data?.[index]?.display_order}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.data?.[index]?.display_order?.message}
                  </Form.Control.Feedback>
                </div>
              </div>
            );
          })}
        </div>
      </Form>
    </div>
  );
}

export default memo(NutritionUnitMasterList);
