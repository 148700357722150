export enum PRIVILEGE_ROLE {
  NORMAL = 'normal',
  APPROVAL = 'approval',
}

export enum PRIVILEGE_STATUS {
  NEW = '新規',
  PENDING_CONFIRMATION = '確認待ち',
  PENDING_APPROVAL = '承認待ち',
  APPROVED = '承認済み',
  REJECTED = '',
}

export const MAP_PRIVILEDGE_TO_PRIVILEGE_STATUS: any = {
  [PRIVILEGE_STATUS.NEW]: 'new',
  [PRIVILEGE_STATUS.PENDING_CONFIRMATION]: 'wait_for_confirmed',
  [PRIVILEGE_STATUS.PENDING_APPROVAL]: 'wait_for_approval',
  [PRIVILEGE_STATUS.APPROVED]: 'admitted',
  [PRIVILEGE_STATUS.REJECTED]: 'none',
};
