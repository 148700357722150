const CREATE_TABLE_HEADER = [
  {
    title: 'code',
    width: '10%',
  },
  {
    title: 'name',
    width: '8%',
  },
  {
    title: 'usage_amount',
    width: '8%',
  },
  {
    title: 'sort_key',
    width: '5%',
  },
  {
    title: 'display_group',
    width: '5%',
  },
  {
    title: 'apply_start_date',
    width: '10%',
  },
  {
    title: 'apply_end_date',
    width: '10%',
  },
  {
    title: 'remark',
    // width: '20%',
  },
  {
    title: 'delete',
    width: '5%',
  },
];

export { CREATE_TABLE_HEADER };
