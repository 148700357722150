import { remainWidthAfterSelection } from 'features/PDF/components/api/api-header';
import { PDF_COL_STYLE_TABLE_BY_TYPE, PDF_COLOR, PDF_HIDDEN_BRIGHT } from 'features/PDF/constants/style';
import '../../style.scss';
import SelectMenuColor from '../SelectMenuColor/SelectMenuColor';

const CategoryRow = ({ styles, record, isSelected, onChangeCategoryTitle, isAllowedEdit, isDraft }: any) => {
  const isAdded = record.isAdded;
  return (
    <div style={{ ...styles.row }}>
      {/* Select temp col */}
      <div
        style={{
          ...styles.col,
          ...styles.colApprove,
          padding: 0,
          backgroundColor: 'white',
          width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%',
          position: 'relative',
        }}
      >
        <SelectMenuColor isSelected={isSelected} />
      </div>
      <div
        style={{
          ...styles.col,
          ...styles.colConfirm,
          padding: 0,
          backgroundColor: 'white',
          width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%',
          position: 'relative',
        }}
      >
        <SelectMenuColor isSelected={isSelected} />
      </div>

      {/* Check box */}
      <div
        style={{
          ...styles.col,
          ...styles.colCategory,
          ...{
            backgroundColor: PDF_COLOR['background'][record.style.backgroundColor],
          },
          ...(record.isHidden ? { filter: `brightness(${PDF_HIDDEN_BRIGHT}%)` } : {}),
          width: remainWidthAfterSelection() + '%',
          position: 'relative',
        }}
      >
        <SelectMenuColor isSelected={isSelected} />
        <input
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="chart_text"
          type="text"
          value={record.title}
          onChange={(e: any) => onChangeCategoryTitle(e, record)}
          disabled={!isAllowedEdit}
          style={{
            backgroundColor: PDF_COLOR['background'][record.style.backgroundColor],
            border: 'none',
            borderRight: 'solid 1px black',
            width: '27.3%',
          }}
          autoFocus={isAdded && !isDraft}
        />
      </div>
    </div>
  );
};

export { CategoryRow };
