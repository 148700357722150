import nutritionMasterService from 'features/MasterDataManagement/NutritionMaster/nutritionMasterService';
import nutritionUnitMasterService from 'features/MasterDataManagement/NutritionUnitMaster/nutritionUnitMasterService';
import { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LiaTimesSolid } from 'react-icons/lia';
import { POST_DETAIL_COMPOSITION_TABLE_HEADER, PRE_DETAIL_COMPOSITION_TABLE_HEADER } from './constants/tableHeader';
import {
  ELEMENT_PREFIX,
  PROCESS_PRODUCT_PREFIX,
  PURCHASE_PRODUCT_PREFIX,
} from 'features/CalorieManagement/ItemReplacement/constant';
import CompositionDetailService from './compositionDetailService';
import { ALLERGY_RAW_MATERIALS_TYPE, QUASI_RAW_MATERIALS_TYPE } from './constants/allergyRawMaterialsType';
import { KCAL_UNIT } from 'constants/kcalUnit';

interface ViewDetailCompositionModalProps {
  onClose: () => void;
  chosenDetailComposition: string | null;
}

const ViewDetailCompositionModal = ({ onClose, chosenDetailComposition }: ViewDetailCompositionModalProps) => {
  const { t } = useTranslation();
  const [nutritionMasterHeaders, setNutritionMasterHeaders] = useState<any>([]);
  const [composition, setComposition] = useState<any>(null);

  const fetchCompositionDetail = async (searchText: string) => {
    try {
      const res = await CompositionDetailService.get({ searchText });
      return res.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchNutritionMaster = async () => {
    try {
      const nutritionRes = await nutritionMasterService.getAll();
      const unitRes = await nutritionUnitMasterService.getAll();
      const res = nutritionRes.data.map((nutrition) => {
        return {
          ...nutrition,
          nutrition_unit: `(${
            unitRes.data.find((unit) => unit.nutrition_unit_cd === nutrition.nutrition_unit_master)?.nutrition_unit_name
          })`,
        };
      });
      setNutritionMasterHeaders(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      fetchNutritionMaster();
      if (!chosenDetailComposition) return;

      let mappedItem = {};
      const item = await fetchCompositionDetail(chosenDetailComposition);

      if (!item) return;
      if (chosenDetailComposition[0] === ELEMENT_PREFIX) {
        mappedItem = {
          code: chosenDetailComposition,
          name: item?.element_item?.element_name,
          nutritions: item?.nutritions,
          allergies: item?.allergies,
        };
      }
      if (chosenDetailComposition[0] === PURCHASE_PRODUCT_PREFIX) {
        mappedItem = {
          code: chosenDetailComposition,
          name: item?.purchase_item?.purchase_item_name,
          nutritions: item?.nutritions,
          allergies: item?.allergies,
        };
      }
      if (chosenDetailComposition[0] === PROCESS_PRODUCT_PREFIX) {
        mappedItem = {
          code: chosenDetailComposition,
          name: item?.process_item?.process_item_name,
          nutritions: item?.nutritions,
          allergies: item?.allergies,
        };
      }
      setComposition(mappedItem);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mappedAllergyByType = (allergies: any, type: number) => {
    return allergies
      ?.filter((allergy: any) => allergy.allergy_item_master?.allergy_item_cd !== 3)
      .filter((allergy: any) => allergy.allergy_master?.type === type);
  };

  const allergySpecificRawMaterials = mappedAllergyByType(composition?.allergies, ALLERGY_RAW_MATERIALS_TYPE);
  const quasiSpecificRawMaterials = mappedAllergyByType(composition?.allergies, QUASI_RAW_MATERIALS_TYPE);

  if (!composition) return <></>;

  return (
    <Modal show size="xl" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
      <Modal.Header className="border-bottom-0 justify-content-end">
        <Button variant="primary" className="btn--auto" onClick={onClose}>
          <LiaTimesSolid size={16} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* list */}
        <div className="sticky-table flex-grow-1 flex-shrink-1">
          <Table>
            <thead>
              <tr>
                {PRE_DETAIL_COMPOSITION_TABLE_HEADER.map((header, idx) => (
                  <th style={{ width: header.width }} key={`preMenuHeader_${idx}`}>
                    {t(`${header.title}`)}
                  </th>
                ))}
                {nutritionMasterHeaders.map((nutritionHeader: any, idx: number) => (
                  <th className="text-center" style={{ wordBreak: 'break-word' }} key={`nutritionHeader_${idx}`}>
                    <div className="text-center">{nutritionHeader.nutrition_name}</div>
                    <div className="text-center">{nutritionHeader.nutrition_unit}</div>
                  </th>
                ))}
                {POST_DETAIL_COMPOSITION_TABLE_HEADER.map((header, idx) => (
                  <th style={{ width: header.width }} key={`afterMenuHeader_${idx}`}>
                    {t(`${header.title}`)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{composition.code}</td>
                <td>{composition.name}</td>
                {composition.nutritions.map((nutrition: any, idx: number) => {
                  return (
                    <td key={idx}>
                      {nutrition.nutrition_master?.nutrition_unit_master === KCAL_UNIT
                        ? nutrition?.amount?.toFixed(0)
                        : nutrition?.amount?.toFixed(1)}
                    </td>
                  );
                })}
                <td>
                  {allergySpecificRawMaterials.map((allergy: any, idx: number) => {
                    return allergy?.allergy_item_master?.allergy_item_name
                      ? `${allergy.allergy_master.allergy_name} (${allergy.allergy_item_master.allergy_item_name})${
                          idx === allergySpecificRawMaterials.length - 1 ? '' : ', '
                        }`
                      : '';
                  })}
                </td>
                <td>
                  {quasiSpecificRawMaterials.map((allergy: any, idx: number) => {
                    return allergy?.allergy_item_master?.allergy_item_name
                      ? `${allergy.allergy_master.allergy_name} (${allergy.allergy_item_master.allergy_item_name})${
                          idx === quasiSpecificRawMaterials.length - 1 ? '' : ', '
                        }`
                      : '';
                  })}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewDetailCompositionModal;
