import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MenuCategoryMasterUpdatePayload } from 'models';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  businessFormatMasterActions,
  selectBusinessFormatMasterData,
} from 'store/BusinessFormatMaster/businessFormatMasterSlice';
import {
  menuCategoryMasterActions,
  selectMenuCategoryMasterData,
} from 'store/MenuCategoryMaster/menuCategoryMasterSlice';
import MenuCategoryMasterFilter from './components/MenuCategoryMasterFilter';
import MenuCategoryMasterForm from './components/MenuCategoryMasterForm';
import MenuCategoryMasterList from './components/MenuCategoryMasterList';
import useMenuCategoryMaster from './hooks/useMenuCategoryMaster';
import './style.scss';

export type MenuCategoryMasterListFormValues = {
  data: MenuCategoryMasterUpdatePayload[];
};

export type MenuCategoryMasterFilterValues = {
  businessType: string;
};

function MenuCategoryMasterFeature() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // hooks
  const { updateMenuCategoryMaster } = useMenuCategoryMaster();

  // refs
  const formRef = useRef<HTMLFormElement>(null);
  const nextDisplayOrder = useRef<number>(1);

  // states
  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState<MenuCategoryMasterFilterValues>();

  // selectors
  const menuCategoryMasterList = useAppSelector(selectMenuCategoryMasterData);
  const businessFormatMasterList = useAppSelector(selectBusinessFormatMasterData);

  // side effects
  useEffect(() => {
    dispatch(businessFormatMasterActions.fetchBusinessFormatMasterList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filter) {
      dispatch(menuCategoryMasterActions.fetchMenuCategoryMasterList(filter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (businessFormatMasterList.length > 0 && filter === undefined) {
      setFilter({
        businessType: businessFormatMasterList[0].business_format_cd,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessFormatMasterList]);

  useEffect(() => {
    if (menuCategoryMasterList.length === 0) {
      nextDisplayOrder.current = 1;
      return;
    }

    const maxDisplayOrder = menuCategoryMasterList.reduce(
      (max, item) => (item.display_order > max ? item.display_order : max),
      menuCategoryMasterList[0].display_order
    );
    nextDisplayOrder.current = maxDisplayOrder + 1;
  }, [menuCategoryMasterList]);

  // events
  function handleOpenForm() {
    setShowForm(true);
  }

  function handleCloseForm() {
    setShowForm(false);
  }

  function handleMenuCategoryMasterFormReflect() {
    dispatch(menuCategoryMasterActions.fetchMenuCategoryMasterList(filter));
  }

  function handleSubmit() {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  }

  function handleSubmitMenuCategoryMasterListForm(formValues: MenuCategoryMasterListFormValues) {
    (async () => {
      const { data, error } = await updateMenuCategoryMaster(formValues.data);
      if (data) {
        toast.success(data?.message);
        dispatch(menuCategoryMasterActions.fetchMenuCategoryMasterList(filter));
      } else {
        if (error && error.error) {
          toast.error(<div dangerouslySetInnerHTML={{ __html: error.message || t('App.unexpected_error') }}></div>);
        }
      }
    })();
  }

  function handleFilter(filter: MenuCategoryMasterFilterValues) {
    setFilter({
      ...filter,
      businessType: filter.businessType,
    });
  }

  return (
    <div className="page-content d-flex flex-column">
      {/* header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('MenuCategoryMaster.head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button onClick={handleSubmit}>{t('MenuCategoryMaster.update')}</Button>
              <Button onClick={handleOpenForm}>{t('MenuCategoryMaster.register')}</Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* filter */}
      <MenuCategoryMasterFilter businessTypeData={businessFormatMasterList} onFilter={handleFilter} />

      {/* list */}
      <MenuCategoryMasterList
        businessTypeData={businessFormatMasterList}
        formRef={formRef}
        data={menuCategoryMasterList}
        onSubmitForm={handleSubmitMenuCategoryMasterListForm}
      />

      {/* Form Modal */}
      {showForm &&
        createPortal(
          <MenuCategoryMasterForm
            nextDisplayOrder={nextDisplayOrder.current}
            onClose={handleCloseForm}
            onReflect={handleMenuCategoryMasterFormReflect}
          />,
          document.body
        )}
    </div>
  );
}

export default MenuCategoryMasterFeature;
