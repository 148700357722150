import { Button, Modal } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  title: string;
  message: string | JSX.Element[];
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ show, onHide, onConfirm, title, message }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5 text-warning">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-6 text-start m-auto">{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t('CalorieAllergySetting.DialogConfirm.btn_cancel')}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {t('CalorieAllergySetting.DialogConfirm.btn_ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
