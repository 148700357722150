import { DonLogoIcon } from 'assets/logos/don';
import { YellowFrame } from './YellowFrame';

const INFO_LEFT_MARGIN = 16;

const DonTemplate = ({ onDateChange, date, isAllowedEdit }: any) => {
  return (
    <div>
      <div
        style={{
          margin: '10 0',
          width: '100%',
          fontWeight: 'light',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 2 }}>
          <DonLogoIcon height={100} width={200} />
        </div>

        {/* text in black frame */}
        <div
          style={{
            width: '310px',
            fontWeight: 'bold',
            padding: '1px 2px',
            marginBottom: 3,
            border: 'solid 1px black',
          }}
        >
          <div>食品衛生法に基づき、下記について表示をしております</div>
        </div>

        <div style={{ marginLeft: INFO_LEFT_MARGIN }}>
          {/* 1 sentance */}
          <div>
            <p style={{ marginBottom: 1 }}>
              アレルゲン27品目を含む商品「○」、原材料にコンタミの可能性があるものは「▲」を表示しております。
            </p>
          </div>

          {/* 2 sentance */}
          <div>
            <p style={{ marginBottom: 1 }}>
              栄養成分値は日本食品標準成分表による数値および、検査機関での分析値を元に算出しております。
            </p>
          </div>

          {/* 3 sentance */}
          <div style={{ marginBottom: 5 }}>
            ライス、パン、スープバー、ドリンクバーを含んだ栄養成分値とアレルゲン、選べるソース・卓上のソースをかけた場合の栄養成分値とアレルゲンはこちらに表示しておりません。
          </div>

          {/* 4 sentance */}
          <div style={{ marginBottom: 5 }}>
            あらかじめご了承ください。　※それぞれ別ページに詳細を記載しておりますのでご参照ください。
          </div>

          {/* 5 sentance */}
          <div style={{ marginBottom: 2 }}>
            ★該当アレルゲンを含む商品として「○」表記のあるものから、下記のように注釈を入れております。
          </div>
        </div>

        {/* yello frame */}
        <YellowFrame />

        {/* 6 sentance */}
        <div>
          ※店舗によりメニュー、原材料が異なる場合がございます。　また、同一メニューであっても原材料は都合により変わることがあります。
        </div>
      </div>
    </div>
  );
};

export { DonTemplate };
