import { PayloadAction } from '@reduxjs/toolkit';
import { handleResponseError } from 'app/rootSaga';
import { AxiosError, AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { Company } from './models/company';
import { call, put, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import authService from './authService';
import { authActions } from './authSlice';
import { Login, Role, User } from './models/login';
import { ApiError } from 'models';

function* yieldLogout(): Generator {
  try {
    yield call(authService.postLogout);
    yield put(authActions.actionLogoutSuccess());
  } catch (e) {
    yield put(authActions.actionLogoutFailed(e as AxiosError));
    yield handleResponseError(e as AxiosError);
  }
  yield put(push('/login'));
}
function* yieldLogin(action: PayloadAction<Login>): Generator {
  try {
    const result = yield call(authService.postLogin, action.payload);
    const response: AxiosResponse<User> = result as AxiosResponse<User>;
    yield put(authActions.actionLoginSuccess(response.data));
  } catch (e) {
    yield put(authActions.actionLoginFailed(e as AxiosError<ApiError>));
  }
}

function* yieldFetchCompanyList() {
  try {
    const response: AxiosResponse<Company[]> = yield call(authService.getCompanies);
    yield put(authActions.fetchCompanySuccess(response));
  } catch (e) {
    yield put(authActions.fetchCompanyFailed());
    yield handleResponseError(e as AxiosError);
  }
}

function* yieldUserSetSelectedJob(action: PayloadAction<Role>): Generator {
  try {
    const level = Object.values(action.payload.levels)[0] || '';
    yield call(authService.postUserSelectedJobs, level);
    yield put(authActions.actionSetSelectedJobSuccess(action.payload));
  } catch (e) {
    yield put(authActions.actionSetSelectedJobFailed());
    yield handleResponseError(e as AxiosError);
  }
}

function* yieldActionTokenExpired(): Generator {
  yield put(authActions.actionTokenExpiredSuccess());
  const currentPath = window.location.pathname;
  const query = new URLSearchParams(window.location.search);
  const redirectUrl = query.get('redirectUrl') || (currentPath.startsWith('/login') ? undefined : currentPath);
  yield put(push(`/login${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`));
}

export default function* authSaga() {
  yield takeLatest(authActions.actionLogin.type, yieldLogin);
  yield takeLatest(authActions.actionLogout.type, yieldLogout);
  yield takeLatest(authActions.fetchCompanyList.type, yieldFetchCompanyList);
  yield takeLatest(authActions.actionSetSelectedJob.type, yieldUserSetSelectedJob);
  yield takeLeading(authActions.actionTokenExpired.type, yieldActionTokenExpired);
}
