import { NotFound } from 'components/common/NotFound';
import StatusHeader from 'components/common/StatusHeader';
import CalorieManagement from 'features/CalorieManagement';
import DemoFeature from 'features/Demo';
import MasterDataManagement from 'features/MasterDataManagement';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

export function AppLayout() {
  const history = useHistory();

  return (
    <>
      <section className="main-content">
        <div className="page-wrapper">
          <div className="page-header" id="page-header">
            <StatusHeader onAction={() => history.push('/')} />
          </div>
          <div className="page-main">
            <Switch>
              <Route exact path="/">
                <Redirect to="/calorie-management/purchase-processed-calorie-allergy-setting" />
              </Route>
              <Route path="/calorie-management">
                <CalorieManagement />
              </Route>
              <Route path="/master-management">
                <MasterDataManagement />
              </Route>
              <Route path="/demo">
                <DemoFeature />
              </Route>
              <Route path="/404">
                <NotFound />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>

        {/* <div className="page-footer">
          <Footer />
        </div> */}
      </section>
    </>
  );
}
