import { useCallback, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import '../style.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  businessFormatMasterActions,
  selectBusinessFormatMasterData,
} from 'store/BusinessFormatMaster/businessFormatMasterSlice';

interface CheckBoxItemProps {
  selectedValue: string;
  id: string;
  name: string;
  readonly: boolean;
  isInValid?: boolean;
  errors: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBoxBusinessFormatItem = ({
  selectedValue = '',
  readonly,
  id,
  name,
  isInValid,
  errors,
  onChange,
}: CheckBoxItemProps) => {
  const checkBoxRefs = useRef<HTMLInputElement[]>([]);
  const dispatch = useAppDispatch();
  const businessFormatMasterList = useAppSelector(selectBusinessFormatMasterData);

  useEffect(() => {
    if (businessFormatMasterList.length === 0) {
      dispatch(businessFormatMasterActions.fetchBusinessFormatMasterList());
    }
  }, [businessFormatMasterList.length, dispatch]);

  const renderCheckBoxes = useCallback(() => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
    };

    return businessFormatMasterList.map((businessFormatItem, idx) => (
      <Form.Check
        className="anra-checkbox d-inline-block me-4"
        key={idx}
        type="radio"
        label={businessFormatItem.name}
        value={businessFormatItem.business_format_cd}
        onChange={handleChange}
        disabled={readonly}
        checked={selectedValue === businessFormatItem.business_format_cd.toString()}
        ref={(el: HTMLInputElement) => {
          if (el) {
            checkBoxRefs.current[idx] = el;
          }
        }}
        id={`${id}-${businessFormatItem.business_format_cd}`}
        name={name}
        isInvalid={isInValid ?? false}
      />
    ));
  }, [businessFormatMasterList, onChange, readonly, id, isInValid, selectedValue, name]);

  return (
    <div>
      <Form.Group>
        {renderCheckBoxes()}
        {isInValid && <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>}
      </Form.Group>
    </div>
  );
};

export default CheckBoxBusinessFormatItem;
