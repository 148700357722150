export const partsConfigurationJP = {
  PartsConfigurationList: {
    head_title: '部品構成設定',
    update: '更新',
    register: '新規登録',
    delete: '削除',
    allergy_item_name: '名称',
    description: '説明',
    priority: '優先度',
    display_order: '表示順',
    pdf_format: 'カロアレ表書式',
    add: '登録',
    hidden: '非表示',
    edit: '編集',
    go_back: '戻る',
    all: '全て',
    status: 'ステータス',
    pending_confirmation: '確認待ち',
    pending_approval: '承認待ち',
    notification: 'お知らせ',
    changed_only: '変更ありのみ',
    application_period: '適用期間',
    validity_period_only: '有効期間のみ',
    copy_and_register: 'コピーして新規登録',
    note_1: '※●…アレルゲンを含む　▲…コンタミの可能性あり',
    note_2: '※栄養価は総量',
    name_or_code_search: '名称・コード検索',
    element_classification: '部品分類',
    table: {
      selection: '選択',
      status: 'ステータス',
      notification: 'お知らせ',
      business_format: '業態',
      element_code: '部品コード',
      name: '名称',
      specific_raw_materials: '特定原材料',
      items_equivalent_to_specific_raw_materials: '特定原材料に準ずる品目',
      usage_classification: '利用区分',
      confirmed_by: '確認者',
      confirmed_date: '確認日',
      last_update_date: '最終更新日',
      last_update_by: '最終更新者',
    },
  },
  PartsConfigurationDetail: {
    head_title: '部品構成設定（更新）',
    head_title_new: '部品構成設定（新規登録）',
    element_cd: '部品コード',
    element_name: '名称',
    business_format: '業態',
    element_classification: '部品分類',
    suspend_date: '利用停止日',
    composition: '構成',
    composition_unit: '部品単位',
    element_sub_unit_1: '補助単位1',
    element_sub_unit_2: '補助単位2',
    subunit_conversion_rate_1: '補助単位換算率1',
    subunit_conversion_rate_2: '補助単位換算率2',
    filter: '絞込',
    reset: '解除',
    add: '追加',
    delete: '追加',
    register: '登録',
    application_date: '適用日',
    confirm_request: '確認依頼',
    approval_request: '承認依頼',
    approval: '承認',
    remand: '差戻',
    go_back: '戻る',
    remark: '■備考',
    changed_sign: '●',
    table: {
      changed: '変更有',
      remark: '備考',
      configuration_cd: '構成コード',
      configuration_name: '名称',
      usage_amount: '使用量',
      sort_key: 'ソートキー',
      application_start_date: '適用開始日',
      application_end_date: '適用終了日',
      delete: '削除',
    },
    validation: {
      required: 'この項目は必須です',
      positive: '正の数値を入力してください',
      min: '最小値は0以上の数値を入力してください',
      number: '数値を入力してください',
    },
  },
};
