import { Form, Table } from 'react-bootstrap';
import { POST_MENU_TABLE_HEADER, PRE_MENU_TABLE_HEADER } from '../constants/tableHeader';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ALLERGY_RAW_MATERIALS_TYPE, QUASI_RAW_MATERIALS_TYPE } from '../constants/allergyRawMaterialsType';
import { Nutrition } from 'features/CalorieManagement/MenuStructureSetting/models/MenuStructureSettingList';
import { KCAL_UNIT } from 'constants/kcalUnit';

const MenuTable = ({
  menus,
  nutritionHeaders,
  onSelect,
  selectedId,
}: {
  menus: any[];
  nutritionHeaders: any[];
  onSelect: (id: number) => void;
  selectedId: number | null;
}) => {
  const { t } = useTranslation();

  const mappedAllergyByType = (allergies: any, type: number) => {
    return allergies
      ?.filter((allergy: any) => allergy.allergy_item_master?.allergy_item_cd !== 3)
      .filter((allergy: any) => allergy.allergy_master?.type === type);
  };

  return (
    <div className="sticky-table flex-grow-1 flex-shrink-1">
      <Table>
        <thead>
          <tr>
            {PRE_MENU_TABLE_HEADER.map((header, idx) => (
              <th style={{ width: header.width }} key={`preMenuHeader_${idx}`}>
                {t(`${header.title}`)}
              </th>
            ))}
            {nutritionHeaders.map((nutritionHeader, idx) => (
              <th style={{ wordBreak: 'break-word' }} key={`nutritionHeader_${idx}`}>
                <div>{nutritionHeader.nutrition_name}</div>
                <div>{nutritionHeader.nutrition_unit}</div>
              </th>
            ))}
            {POST_MENU_TABLE_HEADER.map((header, idx) => (
              <th style={{ width: header.width }} key={`afterMenuHeader_${idx}`}>
                {t(`${header.title}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {menus.map((menu, idx) => {
            const allergySpecificRawMaterials = mappedAllergyByType(menu.allergies, ALLERGY_RAW_MATERIALS_TYPE);
            const quasiSpecificRawMaterials = mappedAllergyByType(menu.allergies, QUASI_RAW_MATERIALS_TYPE);
            const isChange = menu.change_flag;
            const isUnregister = menu.is_any_unregistered;

            return (
              <tr key={idx}>
                <td>
                  <Form.Check
                    className="anra-checkbox d-flex justify-content-center"
                    label=""
                    type="checkbox"
                    onChange={() => onSelect(menu.menu_cd)}
                    checked={selectedId === menu.menu_cd}
                  />
                </td>
                <td className="text-center">{menu.status === '' ? '' : menu.status}</td>
                <td className="text-center" style={{ color: 'red' }}>
                  {isUnregister
                    ? t('MenuStructureSettingScreen.unregister')
                    : isChange
                    ? t('MenuStructureSettingScreen.change')
                    : ''}
                </td>
                <td>{menu.menu_cd}</td>
                <td>{menu.menu_name}</td>
                {menu.nutritions.map((nutrition: Nutrition, idx: number) => (
                  <td key={idx}>
                    {nutrition?.nutrition_master?.nutrition_unit_master === KCAL_UNIT
                      ? nutrition?.amount?.toFixed(0)
                      : nutrition?.amount?.toFixed(1)}
                  </td>
                ))}
                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal', minWidth: '200px' }}>
                  {allergySpecificRawMaterials.map((allergy: any, idx: number) => {
                    return allergy?.allergy_item_master?.allergy_item_name
                      ? `${allergy.allergy_master.allergy_name} (${allergy.allergy_item_master.allergy_item_name})${
                          idx === allergySpecificRawMaterials.length - 1 ? '' : ', '
                        }`
                      : '';
                  })}
                </td>
                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal', minWidth: '270px' }}>
                  {quasiSpecificRawMaterials.map((allergy: any, idx: number) => {
                    return allergy?.allergy_item_master?.allergy_item_name
                      ? `${allergy.allergy_master.allergy_name} (${allergy.allergy_item_master.allergy_item_name})${
                          idx === quasiSpecificRawMaterials.length - 1 ? '' : ', '
                        }`
                      : '';
                  })}
                </td>
                <td>{menu.applicant_name}</td>
                <td>{`${menu.applied_date ? dayjs(menu.applied_date).format('YYYY/MM/DD HH:mm') : ''}`}</td>
                <td>{menu.verifier_name}</td>
                <td>{`${menu.verify_date ? dayjs(menu.verify_date).format('YYYY/MM/DD HH:mm') : ''}`}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export { MenuTable };
