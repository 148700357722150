import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AxiosError, AxiosResponse } from 'axios';
import { Company } from './models/company';
import authStorage from './authStorage';
import { Login, Role, Token, User } from './models/login';
import { ApiError } from 'models';

//auth state class
export interface UserState {
  loading: boolean;
  user: User | undefined;
  company_list: Company[];
  selected_job: Role | undefined;
  error_message: string | null;
  formErrors: { [key: string]: string[] } | undefined;
}

// init model state
const initialState: UserState = {
  loading: false,
  company_list: [],
  user: undefined,
  selected_job: undefined,
  error_message: null,
  formErrors: undefined,
};
// slice auth
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    actionTokenExpired(state) {},
    actionTokenExpiredSuccess(state) {
      state.user = undefined;
      state.selected_job = undefined;
      state.error_message = '';
      authStorage.removeTokens();
    },
    actionLogout(state) {
      state.loading = true;
    },
    actionLogoutSuccess(state) {
      state.loading = false;
      state.loading = false;
      state.user = undefined;
      state.selected_job = undefined;
      state.error_message = '';
      authStorage.removeTokens();
    },
    actionLogoutFailed(state, action: PayloadAction<AxiosError>) {
      state.loading = false;
    },
    actionLogin(state, action: PayloadAction<Login>) {
      state.loading = true;
    },
    actionLoginSuccess(state, action: PayloadAction<User>) {
      state.loading = false;
      const userLogin = action.payload as User;
      if (userLogin.roles && userLogin.roles.length > 0) {
        state.selected_job = userLogin.roles[0];
      } else {
        state.selected_job = undefined;
      }
      authStorage.setTokens(userLogin.token as Token);
      const { token, ...userInfo } = userLogin;
      state.user = { token: undefined, ...userInfo };
      state.error_message = '';
      state.formErrors = undefined;
      state.loading = false;
    },
    actionLoginFailed(state, action: PayloadAction<AxiosError<ApiError>>) {
      state.loading = false;
      state.error_message = action.payload?.response?.data?.message || '';
      state.formErrors = action.payload?.response?.data?.error || {};
      state.user = undefined;
      state.selected_job = undefined;
    },
    actionSetSelectedJob(state, action: PayloadAction<Role>) {},
    actionSetSelectedJobSuccess(state, action: PayloadAction<Role>) {
      state.selected_job = action.payload;
    },
    actionSetSelectedJobFailed(state) {
      state.loading = false;
      state.selected_job = undefined;
    },
    fetchCompanyList(state) {
      state.loading = true;
    },
    fetchCompanySuccess(state, action: PayloadAction<AxiosResponse<Company[]>>) {
      state.company_list = action.payload.data;
      state.loading = false;
    },
    fetchCompanyFailed(state) {
      state.loading = false;
    },
    clearErrorMessage(state) {
      state.error_message = null;
    },
    clearFormErrors(state) {
      state.formErrors = undefined;
    },
    clearFormFieldError(state, action) {
      const fieldName = action.payload;
      const errors = state.formErrors;
      if (errors?.hasOwnProperty(fieldName)) {
        delete errors[fieldName];
      }
      state.formErrors = errors;
    },
    actionSetLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const selectCompanyList = (state: RootState) => state.auth.company_list;
export const selectUserSelectedJob = (state: RootState) => state.auth.selected_job;
export const selectIsLoading = (state: RootState) => state.auth.loading;
export const selectErrorMessage = (state: RootState) => state.auth.error_message;
export const selectFormErrors = (state: RootState) => state.auth.formErrors;
const selectUser = (state: RootState) => state.auth.user;

export const selectCompanyOptions = createSelector(selectCompanyList, (companyList) => {
  if (companyList && companyList.length > 0) {
    return companyList.map((company: Company) => ({ value: company.cd, label: company.name }));
  }
  return [];
});

export const selectUserJob = createSelector(selectUser, (user) => {
  return user?.roles;
});

export const selectUserInfo = createSelector(selectUser, (user) => {
  if (user) {
    const { token, ...userInfo } = user;
    return userInfo;
  }
  return user;
});

// Reducer
export const authReducer = authSlice.reducer;
