import { useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LiaTimesSolid } from 'react-icons/lia';

import { useAppSelector } from 'app/hooks';
import MenuStructureSettingService from 'features/CalorieManagement/MenuStructureSetting/screens/menuStructureSettingService';
import menuCategoryMasterService from 'features/MasterDataManagement/MenuCategoryMaster/menuCategoryMasterService';
import { FaSearch } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import {
  businessFormatMasterActions,
  selectBusinessFormatMasterData,
} from 'store/BusinessFormatMaster/businessFormatMasterSlice';
import MenuChartModalTable from '../MenuChartModalTable/menuChartModalTable';

interface Filter {
  business?: string;
  menuCategory?: number;
  status: number;
  notice: number;
}

export interface ReplaceItemModalForm {
  business: string;
  category: string;
  hierarchy: string;
}

const BLANK_OPTION = [
  {
    title: '1',
    value: 0,
  },
  {
    title: '2',
    value: 1,
  },
  {
    title: '3',
    value: 2,
  },
];

const AddMenuChartModal = ({ onClose, onClickAdd, selectedMenuCodes = [], revisionDate }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const businessFormatMasterList = useAppSelector(selectBusinessFormatMasterData);

  const [filter, setFilter] = useState<Filter>({
    business: undefined,
    menuCategory: undefined,
    status: 0,
    notice: 0,
  });
  const [searchText, setSearchText] = useState('');
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [hierarchy, setHierarchy] = useState<any>(BLANK_OPTION[0].value);
  const [menus, setMenus] = useState<any>([]);
  const [categoryOptions, setMenuCategoryOptions] = useState<any>([]);

  // call API
  const fetchMenu = async (page: number) => {
    if (page <= 0) return;
    if (filter.business === undefined || filter.menuCategory === undefined) return;
    try {
      const res = await MenuStructureSettingService.getAll({ searchText, page, ...filter, revisionDate });
      const filteredMenu = res.data?.data.filter((menu: any) => !selectedMenuCodes.includes(menu.menu_cd));
      setMenus(filteredMenu);
      return res;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchMenuCategoryMaster = async (businessType?: string) => {
    try {
      if (businessType === undefined) return;
      const res = await menuCategoryMasterService.getAll({ businessType: businessType });
      const defaultMenuCategory = res.data[0]?.menu_category_cd ?? '';
      setMenuCategoryOptions(res.data);
      setFilter((filter: any) => ({
        ...filter,
        menuCategory: String(defaultMenuCategory),
      }));
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    if (businessFormatMasterList.length === 0) {
      dispatch(businessFormatMasterActions.fetchBusinessFormatMasterList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (businessFormatMasterList.length === 0) return;
    const defaultBusiness = businessFormatMasterList[0]?.business_format_cd;
    setFilter((filter: any) => ({
      ...filter,
      business: defaultBusiness,
    }));
  }, [businessFormatMasterList]);

  useEffect(() => {
    fetchMenuCategoryMaster(filter.business);
  }, [filter.business]);

  useEffect(() => {
    fetchMenu(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.menuCategory]);

  const handleSelect = (id: any) => {
    if (selectedIds.includes(id)) {
      const newSelectedIds = selectedIds.filter((item: any) => item !== id);
      setSelectedIds([...newSelectedIds]);
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleApplySearch = () => {
    fetchMenu(1);
  };

  return (
    <Modal show size="xl" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
      <Modal.Header className="border-bottom-0 justify-content-end">
        <Button variant="primary" className="btn--auto" onClick={onClose}>
          <LiaTimesSolid size={16} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3 ">
          <Row className="align-items-center mb-3 gap-4">
            {/* Search filter  */}
            <Col xs="3">
              <Form.Group as={Row} className="mb-3" controlId="combobox">
                <Row sm="8">
                  <Form.Label column sm="8" className="text-start">
                    {t('CalorieManagementSettingScreen.business')}
                  </Form.Label>
                </Row>
                <Col sm="3">
                  <Form.Select
                    style={{ width: '200px' }}
                    value={filter.business}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        business: e.target.value,
                      })
                    }
                  >
                    {businessFormatMasterList.map((option, idx) => {
                      return (
                        <option key={idx} value={option.business_format_cd}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col xs="3">
              <Form.Group as={Row} className="mb-3" controlId="combobox">
                <Row sm="8">
                  <Form.Label column sm="8" className="text-start">
                    {t('CalorieManagementSettingScreen.category')}
                  </Form.Label>
                </Row>
                <Col sm="3">
                  <Form.Select
                    style={{ width: '200px' }}
                    value={filter.menuCategory}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        menuCategory: Number(e.target.value),
                      })
                    }
                  >
                    {categoryOptions.map((option: any, idx: number) => {
                      return (
                        <option key={idx} value={option.menu_category_cd}>
                          {option.menu_category_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col xs="3">
              <Form.Group as={Row} className="mb-3" controlId="combobox">
                <Row sm="8">
                  <Form.Label column sm="8" className="text-start">
                    {t('CalorieManagementSettingScreen.hierarchy')}
                  </Form.Label>
                </Row>
                <Col sm="3">
                  <Form.Select
                    style={{ width: '200px' }}
                    value={hierarchy}
                    onChange={(e) => setHierarchy(e.target.value)}
                  >
                    {BLANK_OPTION.map((option, idx) => {
                      return (
                        <option key={idx} value={option.value}>
                          {option.title}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row sm="3">
            <InputGroup>
              <FormControl
                placeholder={t('CalorieManagementSettingScreen.name_code_search')}
                aria-label="Search"
                style={{ maxWidth: '400px' }}
                onChange={(e: any) => setSearchText(e.target.value)}
              />
              <InputGroup.Text>
                <FaSearch onClick={handleApplySearch} />
              </InputGroup.Text>
            </InputGroup>
          </Row>
        </div>

        <div>
          <MenuChartModalTable
            selectedIds={selectedIds}
            menus={menus}
            //newMenus.filter((newMenu) => !menus.map((menu: any) => menu.id).includes(newMenu.id))}
            onSelect={handleSelect}
          />
        </div>

        <div className="d-flex justify-content-center" style={{ marginTop: '12px' }}>
          <Button
            onClick={() =>
              onClickAdd(
                menus.filter((menu: any) => selectedIds.includes(menu.menu_cd)),
                hierarchy
              )
            }
          >
            {t('CalorieManagementSettingScreen.add')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddMenuChartModal;
