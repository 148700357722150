import {
  PDF_COL_STYLE_TABLE_BY_TYPE,
  PDF_COMPOSITION_CHANGED_BACKGOUND_COLOR,
  PDF_HIDDEN_BRIGHT,
  PDF_LETTET_BLANK,
  PDF_SUSPEND_BRIGHT,
} from 'features/PDF/constants/style';
import '../../style.scss';
import SelectMenuColor from '../SelectMenuColor/SelectMenuColor';
import dayjs from 'dayjs';

const MenuCompositionRow = ({
  record,
  styles,
  isSelected,
  index,
  onChangeGroupName,
  revisionDate,
  isAllowedEdit,
}: any) => {
  const isOpenGroupRecord = record?.isOpenGroup;
  const isSuspend = dayjs(new Date(revisionDate)).startOf('date') > dayjs(new Date(record.suspendDate)).startOf('date');

  const handleNavigateToMenu = () => {
    window.open(`/calorie-management/menus-structure-setting/edit/${record.id}`);
  };
  return (
    <div key={index + 'composition'}>
      <div>
        {record?.compositions?.map((composition: any, rowIndex: any) => {
          const isGroupRecord = composition.type === 'group';
          const isCompositionRecord = composition.type === 'composition';
          const compositionRecord = composition.value;
          const isChange = composition?.isChange;

          return (
            // VALUE
            <div style={{ ...styles.row }} key={rowIndex + 'compositionRow'}>
              {/* Select temp col */}
              {isGroupRecord && !isOpenGroupRecord ? (
                <></>
              ) : (
                <>
                  <div
                    style={{
                      ...styles.col,
                      ...styles.colApprove,
                      padding: 0,
                      backgroundColor: 'white',
                      width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%',
                      position: 'relative',
                    }}
                  >
                    <SelectMenuColor isSelected={isSelected} />
                  </div>
                  <div
                    style={{
                      ...styles.col,
                      ...styles.colConfirm,
                      padding: 0,
                      backgroundColor: 'white',
                      width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%',
                      position: 'relative',
                    }}
                  >
                    <SelectMenuColor isSelected={isSelected} />
                  </div>
                </>
              )}

              {/* Col */}
              {Object.keys(compositionRecord).map((key: any, colIndex: any) => {
                if (isGroupRecord && !isOpenGroupRecord) return <></>;
                return (
                  // VALUE
                  <>
                    {/* Name col */}
                    {colIndex === 0 ? (
                      <>
                        {/* Group name */}
                        <div
                          key={rowIndex + 'compositionGroupCol'}
                          style={{
                            ...styles.col,
                            ...styles.colValue,
                            ...{
                              ...compositionRecord[key]?.style,
                              width: compositionRecord[key]?.style?.width + '%',
                            },
                            ...(colIndex === 0 ? styles.colNameValue : {}),
                            ...(isSuspend ? { filter: `brightness(${PDF_SUSPEND_BRIGHT}%)` } : {}),
                            ...(record.isHidden ? { filter: `brightness(${PDF_HIDDEN_BRIGHT}%)` } : {}),
                            ...(isChange ? { backgroundColor: PDF_COMPOSITION_CHANGED_BACKGOUND_COLOR } : {}),
                            position: 'relative',
                          }}
                        >
                          <SelectMenuColor isSelected={isSelected} />
                          <div
                            style={{
                              marginLeft: colIndex === 0 ? PDF_LETTET_BLANK * compositionRecord[key]?.blank || 0 : 0,
                              width: '100%',
                            }}
                          >
                            {isGroupRecord ? (
                              <>
                                <input
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  className="chart_text"
                                  type="text"
                                  value={compositionRecord[key]?.value}
                                  onChange={(e: any) => onChangeGroupName(e, record, composition, key)}
                                  disabled={!isAllowedEdit}
                                  style={{
                                    border: 'none',
                                    width: '100%',
                                  }}
                                />
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        {/* Composition name */}
                        <div
                          key={rowIndex + 'compositionChildCol'}
                          style={{
                            ...styles.col,
                            ...styles.colValue,
                            ...{
                              ...compositionRecord[key]?.style,
                              width: compositionRecord[key]?.style?.width + '%',
                              textAlign: 'left',
                            },
                            ...(isSuspend ? { filter: `brightness(${PDF_SUSPEND_BRIGHT}%)` } : {}),
                            ...(record.isHidden ? { filter: `brightness(${PDF_HIDDEN_BRIGHT}%)` } : {}),
                            ...(isChange ? { backgroundColor: PDF_COMPOSITION_CHANGED_BACKGOUND_COLOR } : {}),
                            position: 'relative',
                          }}
                        >
                          <SelectMenuColor isSelected={isSelected} />
                          <div
                            style={{
                              ...(isCompositionRecord ? { color: 'blue' } : {}),
                              marginLeft: colIndex === 0 ? PDF_LETTET_BLANK * compositionRecord[key]?.blank || 0 : 0,
                              width: '100%',
                            }}
                            onClick={handleNavigateToMenu}
                          >
                            {isCompositionRecord ? compositionRecord[key]?.value : ''}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* Other col */}
                    {colIndex > 0 ? (
                      <div
                        key={rowIndex + 'compositionOtherCol'}
                        style={{
                          ...styles.col,
                          ...styles.colValue,
                          ...{
                            ...compositionRecord[key]?.style,
                            width: compositionRecord[key]?.style?.width + '%',
                          },
                          ...(colIndex === 0 ? styles.colNameValue : {}),
                          ...(isSuspend ? { filter: `brightness(${PDF_SUSPEND_BRIGHT}%)` } : {}),
                          ...(record.isHidden ? { filter: `brightness(${PDF_HIDDEN_BRIGHT}%)` } : {}),
                          ...(isChange ? { backgroundColor: PDF_COMPOSITION_CHANGED_BACKGOUND_COLOR } : {}),
                          position: 'relative',
                        }}
                      >
                        <SelectMenuColor isSelected={isSelected} />
                        <div
                          style={{
                            marginLeft: colIndex === 0 ? PDF_LETTET_BLANK * compositionRecord[key]?.blank || 0 : 0,
                          }}
                        >
                          {compositionRecord[key]?.value}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { MenuCompositionRow };
