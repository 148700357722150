import axios, { AxiosError, AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { authActions } from 'features/Auth/authSlice';
import authStorage from 'features/Auth/authStorage';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import { appActions } from 'store/appSlice';
import i18n from 'translation/i18n';
import { store } from '../app/store';
import { ERROR_NOTIFICATION_CODES } from './constants';

const { dispatch } = store;

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    dispatch(appActions.showAppLoading());
    const token = authStorage.getAccessToken();
    config.headers = {
      ...config.headers,
      ...(token ? { Authorization: `Token ${token}` } : {}),
    } as AxiosHeaders;
    return config;
  },
  function (error) {
    dispatch(appActions.hideAppLoading());
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Todo : Handle specific status codes
    dispatch(appActions.hideAppLoading());
    if (window.location.pathname === '/login') {
      return response;
    }
    return response.data;
  },
  function (error: AxiosError) {
    const { t } = i18n;
    dispatch(appActions.hideAppLoading());
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status) {
      if (error.response.status === StatusCodes.UNAUTHORIZED) {
        dispatch(authActions.actionTokenExpired());
      }
      if (ERROR_NOTIFICATION_CODES.includes(Number(error.response.status))) {
        toast.error(`${t('App.unexpected_error')}`);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
